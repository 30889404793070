import { useContext, useEffect, useState } from "react";
import BillModal from "../components/BillModal";
import FormOpener from "../components/FormOpener";
import Navbar from "../components/Navbar";
import PriceButton from "../components/PriceButton";
import axios from "axios";
import { LoaderContext } from "../context/LoaderContext";
import { PlanContext } from "../context/PlansContext";
import { useDispatch, useSelector } from "react-redux";
import { choosedPlan, communicationForm, employmentForm, kycForm, personalForm, proposalData } from "../redux/actions/formsAction";

export default function Details() {
    const dispatch = useDispatch();
    const { setLoading } = useContext(LoaderContext);

    useEffect(() => {
        dispatch(employmentForm());
        dispatch(kycForm());
        dispatch(personalForm());
        dispatch(communicationForm());
        dispatch(choosedPlan());
        dispatch(proposalData());
    }, [dispatch])



    const { loading: eLoading, data: eData, status: eStatus } = useSelector(state => state.employmentForm);
    const { loading: kLoading, data: kData, status: kStatus } = useSelector(state => state.kycForm);
    const { loading: pLoading, data: pData, status: pStatus } = useSelector(state => state.personalForm);
    const { loading: cLoading, data: cData, status: cStatus } = useSelector(state => state.communicationForm);

    useEffect(() => {
        if (!eLoading || !kLoading || !pLoading || !cLoading) {
            setTimeout(() => setLoading(false), 500);
        }
    }, [eLoading, kLoading, pLoading, cLoading]);


    const [local, setLocal] = useState(false);
    const [employmentFilled, setEmploymentFilled] = useState(null);
    const [kycFilled, setKycFilled] = useState(null);
    const [personalDetailsFilled, setPersonalDetailsFilled] = useState(null);
    const [communicationDetailsFilled, setCommunicationDetailsFilled] = useState(null);
    const [coords, setCoords] = useState(null);

    window.onstorage = () => { setLocal(!local) };

    useEffect(() => {
        console.log(eData);
        if (eData && eStatus) {
            const data = [
                { label: "Employment Type", value: eData?.employment_type_name },
                { label: "Company Name", value: eData?.company_name },
                { label: "Workspace Location", value: `${eData?.company_address_locality}, ${eData?.company_address_city}, ${eData?.company_address_state}` },
                { label: "Joining Month", value: eData?.joining_month },
            ]
            setEmploymentFilled(data);
        }

    }, [eLoading]);

    useEffect(() => {
        // const communicationForm = JSON.parse(localStorage.getItem("communicationForm"));
        if (cData && cStatus) {
            const data = [
                { label: "Address", value: cData?.address },
            ]
            setCommunicationDetailsFilled(data);
        }
    }, [cLoading]);

    useEffect(() => {
        if (kData && kStatus) {
            const data = [
                { label: "Pan Number", value: kData?.pan?.pan || kData?.pan?.pan_number },
            ]
            setKycFilled(data);
        }
    }, [kLoading]);

    useEffect(() => {
        if (pData && pStatus) {
            const data = [
                { label: "Name", value: pData?.name || kData?.transaction?.full_name },
                { label: "Date Of Birth", value: pData?.dob || kData?.transaction?.dob },
                { label: "Email", value: pData?.email || kData?.transaction.email },
                { label: "Phone", value: pData?.mobile || "" },
                { label: "Gender", value: pData?.gender || "Others" },
            ]
            setPersonalDetailsFilled(data);
        } else {
            const data = [
                { label: "Name", value: kData?.transaction?.full_name },
                { label: "Date Of Birth", value: kData?.transaction?.dob },
                { label: "Email", value: kData?.transaction?.email },
                { label: "Phone", value: kData?.transaction?.phone_number },
                { label: "Gender", value: kData?.transaction?.gender === "M" ? "Male" : kData?.transaction?.gender === "F" ? "Female" : "Others" },
            ]
            console.log(kData);
            if (data.filter(item => item.value).length >= 2) {
                setPersonalDetailsFilled(data);
            }
        }
    }, [pLoading, kLoading]);

    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         const { latitude, longitude } = position.coords;
    //         console.log('User location:', "latitude", latitude, "longitude", longitude);
    //         setCoords({ latitude, longitude });
    //     }, (error) => {
    //         console.error('Error getting user location:', error.message);
    //     });
    // }, [])

    useEffect(() => {
        if (coords && coords.latitude && coords.longitude) {
            const getAddr = async () => {
                const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${coords.latitude}&lon=${coords.longitude}&zoom=18&addressdetails=1`);
                console.log(response);
            };
            // getAddr();
        }
    }, [coords]);

    const { plans } = useContext(PlanContext);
    useEffect(() => {
        console.log(plans);
    }, [])

    return (
        <>
            <Navbar />
            <div className="mc-main-wrapper mc-flex start">
                <div className="mc-left-container">

                    <FormOpener
                        text={"Employment Details"}
                        id={1}
                        active
                        ifFilled={employmentFilled}
                        updateParentState={() => { dispatch(employmentForm()) }}
                        updateData={() => { setLocal(!local) }}
                    />

                    <FormOpener
                        text={"Kyc Details"}
                        id={2}
                        active={employmentFilled}
                        ifFilled={kycFilled}
                        updateData={() => { dispatch(kycForm()) }}
                    />

                    <FormOpener
                        text={"Personal Details"}
                        id={3}
                        active={kycFilled}
                        ifFilled={personalDetailsFilled}
                        updateData={() => { dispatch(personalForm()) }}
                    />

                    <FormOpener
                        text={"Communication Address"}
                        id={4}
                        active={personalDetailsFilled}
                        ifFilled={communicationDetailsFilled}
                        updateData={() => { dispatch(communicationForm()) }}
                    />
                </div>
                <div className="mc-right-container">
                    <BillModal />
                    <PriceButton />
                </div>
            </div>
        </>
    )
}