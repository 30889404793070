import axios from "axios";
import { baseurl } from "../../constants";

export const postChoosedPlan = async (data) => {
    try {
        const obj = {
            planId: data?.id,
            planName: data?.planName,
            coverages: data?.coverages,
            premium: data?.premium,
            userTransactionId: localStorage.getItem("userTransactionId")
        }
        const response = await axios.post(`${baseurl}/api/protection/choosed-plan`, obj);
        return response;
    } catch (err) {
        return err.message;
    }
}