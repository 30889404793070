

export const getChoosedPlanReducer = (state = {}, action) => {
    switch (action.type) {
        case "choosed_plan_pending":
            return { loading: true, status: 0 }

        case "choosed_plan_success":
            return { loading: false, status: 1, data: action.payload }

        case "choosed_plan_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
} 



export const getEmploymentFormDateReducer = (state = {}, action) => {
    switch (action.type) {
        case "employment_form_pending":
            return { loading: true, status: 0 }

        case "employment_form_success":
            return { loading: false, status: 1, data: action.payload }

        case "employment_form_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}

export const getKYCDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case "kyc_form_pending":
            return { loading: true, status: 0 }

        case "kyc_form_success":
            return { loading: false, status: 1, data: action.payload }

        case "kyc_form_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}

export const getPersonalDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case "personal_form_pending":
            return { loading: true, status: 0 }

        case "personal_form_success":
            return { loading: false, status: 1, data: action.payload }

        case "personal_form_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}  

export const getCommunicationDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case "communication_form_pending":
            return { loading: true, status: 0 }

        case "communication_form_success":
            return { loading: false, status: 1, data: action.payload }

        case "communication_form_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}


export const getProposalDataReducer = (state = {},action) => {
    switch(action.type){
        case "proposal_data_pending":
            return { loading: true, status: 0 }

        case "proposal_data_success":
            return { loading: false, status: 1, data: action.payload }

        case "proposal_data_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;
    }
}