
import { useState } from "react";
import { Modal } from "react-bootstrap"
export default function DeclarationModal({ state, onClose, doAccept, setDoAccept }) {



    const handleChange = (e) => {
        setDoAccept(e.target.checked)
    }

    const handleClose = (e) => {
        if (doAccept) {
            onClose();
        }
    }


    return <Modal className='mc-modal-dialog' centered show={state}>
        <div className='mc-modal-form-container'>
            <h1 className="mc-modal-heading">Declaration</h1>
            <h3 className="mc-modal-headline">I hereby declare that all the information provided by me is true and accurate to my knowledge.</h3>
            <div className="mc-declaration-points">
                <ol>
                    <li>I hereby declare that all the information provided by me is true and accurate to my knowledge.</li>
                    <li>I hereby declare that all the information provided by me is true and accurate to my knowledge.</li>
                    <li>I hereby declare that all the information provided by me is true and accurate to my knowledge.</li>
                    <li>I hereby declare that all the information provided by me is true and accurate to my knowledge.</li>
                </ol>
            </div>
            <div className='mc-flex' >
                <div className="mc-flex mc-check-container">
                    <input type="checkbox" onChange={handleChange} />
                    <span>I accept the Terms & Conditions</span>
                </div>
                <button className={doAccept ? "mc-btn mc-verify-button" : "mc-btn mc-verify-button mc-disabled-btn"} style={{ padding: 12 }} onClick={handleClose}>I agree</button>
            </div>
        </div>
    </Modal>
}