import { createContext, useState } from "react";

export const PlanContext = createContext();

export default function PlanProvider({ children }) {
  const [plans, setPlans] = useState(null);

  return (
    <PlanContext.Provider value={{ plans, setPlans }}>
      {children}
    </PlanContext.Provider>
  );
}
