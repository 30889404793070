import React from "react";

const OrderDetails = ({ details }) => {
  console.log(details?.response?.result?.data[0]);
  return (
    <div className="container mb-3">
      {details && <div className="row">
        <div className="col">
          <p className="heading-text">Protection Plan Id</p>
          <p className="value-text">{details?.response?.result?.data[0].protectionPlanId}</p>
        </div>
        <div className="col">
          <p className="heading-text">Policy Start Date</p>
          <p className="value-text">{new Date(details?.response?.result?.data[0].policyStartDate * 1000).toLocaleDateString() || ""}</p>
        </div>
        <div className="col">
          <p className="heading-text">Policy End Date</p>
          <p className="value-text">{new Date(details?.response?.result?.data[0]?.policyEndDate * 1000).toLocaleDateString() || ""}</p>
        </div>
        <div className="col">
          <p className="heading-text">Amount</p>
          <p className="value-text">₹ {Math.ceil(details?.response?.result?.data[0]?.netAmount) || ""}</p>
        </div>
      </div>}
    </div>
  );
};

export default OrderDetails;
{/* <div className="order-details">
<div className="order-wrapper">
  <div>
  <div> <p className="heading-text">Order Date</p></div>
   
  </div>
  <div>
  <div>
    <p className="value-text">26 April 2023</p></div>
  </div>
</div>
<div className="order-wrapper">
  <div>
  <div>  <p className="heading-text">Order Date</p></div>
  
  </div>
  <div>
  <div>
    <p className="value-text">BK935663256</p></div>
  </div>
</div>
<div className="order-wrapper">
  <div><p className="heading-text">Payment</p></div>  
 <div>  <p className="value-text">Visa -5693</p></div>    
</div>
<div className="order-wrapper">
<div>
  <p className="heading-text">Address</p>
  <div>
    <div>
    <p className="value-text">8502 Preston Road</p></div>
  </div>
</div>
</div>
</div> */}