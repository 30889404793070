import React, { useState } from "react";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log(e);
  //   // Handle form submission
  // };

  return (
    <div className="newletter-conatiner row g-0">
      <div className="col-md-1"></div>
      <div className="newletter-text-wrapper col-md-5">
        <p className="newletter-heading">Sign Up to Get Latest Update</p>
        <p className="newsletter-text">
          It is a long established fact that a reader will be distracted by the.
        </p>
      </div>
      <div className="col-md-6">
        <div className="newsletter-input ">
        <input
          type="email"
          className="form-control inputbox"
          id="emailInput"
          value={email}
          onChange={handleEmailChange}
          placeholder="Email Address*"
          required
        />
        <button className="newsletter-button">Subscribe</button>
        </div>
      </div>  
    </div>
  );
};

export default Newsletter;
