import axios from "axios";
import { baseurl } from "../../constants";

export const getProposalId = async (data) => {
    try {
        console.log(data);
        const form = {
            name: data?.personal?.name?.toString(),
            dob: data?.personal?.dob?.toString(),
            email: data?.personal?.email?.toString(),
            mobile: data?.personal?.mobile?.toString(),
            gender: data?.personal?.gender?.toString(),
            address: data?.communication?.address?.toString(),
            pincode: data?.communication?.pincode?.toString(),
            state: data?.communication?.state_code?.toString(),
            companyId: data?.employment?.company_id?.toString(),
            companyPincodeId: data?.communication?.pincode?.toString().slice(0, 5),
            dateOfJoining: data?.employment?.joining_month?.toString(),
            pan:data?.kycForm?.pan?.pan?.toString(),
            userTransactionId: data?.user?.userTransactionId,
            planId: data?.planData?.planId,
        }
        const response = await axios.post(`${baseurl}/api/protection/proposal`, form);
        return response;
    } catch (err) {
        return err.message;
    }
}