import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import QuestionAndAnswer from "../components/Question";
import { PlanContext } from "../context/PlansContext";
import { AccountContext } from "../context/AccountContext";

const PickAPlan = ({ setTotal, setSelected }) => {
  const navigate = useNavigate();
  const { plans } = useContext(PlanContext);
  const { user, setUser } = useContext(AccountContext);
  const [salary, setSalary] = useState(
    plans && Math.floor(plans[0].coverages[0].protectionAmount / 3)
  );
  const [emergencyFund, setEmergencyFund] = useState(
    plans && Math.floor(plans[0]?.coverages[1]?.protectionAmount / 3)
  );
  const [showModal, setShowmodal] = useState(false);
  const [activeButton, setActiveButton] = useState("");

  useEffect(() => {
    if (!plans) {
      console.log("null hain bhai");
      navigate("/"); // Redirect to the homepage if plans is null
    } else {
      const storedPlanId = localStorage.getItem("planId");
      if (!storedPlanId) {
        // setActiveButton(plans[0].id);
        setActiveButton(plans[0].id);
        // localStorage.setItem("planId", plans[0].id);
      } else {
        setActiveButton(storedPlanId);
      }
    }
  }, [plans]);

  const handleIconClick = () => {
    setShowmodal(true);
  };

  const handleModalClose = () => {
    setShowmodal(false);
  };
  const handlePlanButton = (planId) => {
    console.log(planId);
    setSelected(planId);
    // localStorage.setItem("planId", planId.id);
    setUser({ ...user, planId });
    const superPlans = plans.filter((plan) => plan.id === planId.id);
    const temp = Math.floor(superPlans[0].coverages[0].protectionAmount / 3);
    const temp1 = Math.floor(superPlans[0]?.coverages[1]?.protectionAmount / 3);
    const totalAmount = Math.floor(superPlans[0].premium.netAmount);
    setTotal(totalAmount);
    setSalary(temp);
    setEmergencyFund(temp1);
    setActiveButton(planId.id);
  };

  return (
    <div>
      <div className="card-container">
        <div className="button-container">
          {plans &&
            plans.map((plan) => (
              <>
                <div
                  className={
                    activeButton === plan.id
                      ? "active-button plan-btn"
                      : "plan-btn"
                  }
                  key={plan.id}
                  onClick={() => handlePlanButton(plan)}
                >
                  {plan.planName}
                </div>
                <div className="btn-line"></div>
              </>
            ))}
        </div>
        <div className="text-wrapper">
          <p className="paragraph-text">What is covered?</p>
          <p className="protecting-finance-text">Protecting your finances</p>
        </div>
        
        <div>
          <div className="sub-card m-3">
            <div className="d-flex sub-card-container">
              <div className="width-50 mt-2">
                <p className="month-text">
                  For the first 3 <br />
                  months
                </p>
              </div>
              <div className="width-50 mt-2">
                <p className="month-text">
                  Get a salary of <br />
                  <span className="salary-text">{salary}/</span> month
                </p>
              </div>
            </div>

            {/* {plans[0]?.coverages[1]?.protectionAmount && */}
                {/* <div className="d-flex sub-card-container">
                  <div className="width-50">
                    <p className="month-text">
                      For the first 3 <br />
                      months
                    </p>
                  </div>

                  <div className="width-50">
                    <p className="month-text">
                      Get an emergency fund <br />
                      <span className="salary-text">{emergencyFund}/</span>{" "}
                      month
                    </p>
                  </div>
                </div> */}
{/* } */}
            
          </div>

          <div className="text-wrapper">
            <p className="text-1">Protecting your future</p>
            <div className="d-flex align-items-center mb-3">
              <div className="benefit-icon align-items-center"></div>
              <div className="d-flex">
                <p className="benefits-text"> Personal placement concierge</p>
              </div>
              <div className="mx-2 i-icon" onClick={handleIconClick}>
                <img src="/images/frameicon.png" />
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="benefit-icon align-items-center"></div>
              <div className="d-flex">
                <p className="benefits-text"> Curated placement network</p>
              </div>
              <div className="mx-2 i-icon" onClick={handleIconClick}>
                <img src="/images/frameicon.png" />
              </div>
            </div>
          </div>
          <Modal centered show={showModal}>
            <div className="disclaimer-modal">
              <span class="close" onClick={handleModalClose}>
                &times;
              </span>
              <div className="mt-4">
                <h2 className="mb-4">What is covered</h2>
                <div>
                  <p className="headlines-title">Protecting your future</p>
                </div>
                <div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div>
                  <p className="headlines-title">Protecting your future</p>
                </div>
                <div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <div>
                  <p className="headlines-title">Protecting your future</p>
                </div>
                <div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </div>
              <div>
                <QuestionAndAnswer />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default PickAPlan;
