import React from 'react'

const TermAndCondition = () => {
  return (
    <div className='question-container'>
    <a href="/" className='termandcondition'>Terms and conditions</a>
    <p>Powered By assurekit</p>
    </div>
  )
}

export default TermAndCondition;