import { useContext } from "react";
import PaymentSuccess from "../components/PaymentSuccess";
import { LoaderContext } from "../context/LoaderContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function CompanyAdded() {

    const { setLoading } = useContext(LoaderContext);
    const nav = useNavigate();

    useEffect(() => {
        setLoading(false);
    }, [])
    return <div className="mc-main-container">
        <div className="mc-info-container">
            <PaymentSuccess response={"companyAdded"} />
            <button className="mc-btn-back-to-home" onClick={()=>{nav("/")}}>Back To Home</button>
        </div>
    </div>
}