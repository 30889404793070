import axios from "axios";
import { baseurl } from "../../constants";
import { toast } from "react-toastify";

export const paymentLedger = () => async (dispatch) => {
    try {
        dispatch({ type: "payment_ledger_pending" });
        const response = await axios.get(`${baseurl}/api/payment/get-ledger-data?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "payment_ledger_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "payment_ledger_failure", payload: err.message });
    }
}

export const paymentDetails = (paymentId, paymentTransactionId) => async (dispatch) => {
    console.log(paymentTransactionId)
    console.log("paymentId" + paymentId);
    try {
        dispatch({ type: "payment_details_pending" });
        const response = await axios.get(`${baseurl}/api/payment/get-payment-detail/${paymentId}`, {
            headers: {
                paymentTransactionId,
                userTransactionId: localStorage.getItem("userTransactionId")
            }
        });
        if(response.status === 201){
            toast.info("Error while fetching payment details.");
        }
        dispatch({ type: "payment_details_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "payment_details_failure", payload: err.message });
    }
}

export const protectionPlan = (protectionPlan, mobile) => async (dispatch) => {
    try {
        console.log("protection plan dispatched");
        dispatch({ type: "protection_plan_pending" });
        const response = await axios.get(`${baseurl}/api/protection/protection-plan?protectionPlanId=${protectionPlan}&mobile=${mobile}`, {
            headers: {
                "user_transaction_id": localStorage.getItem("userTransactionId")
            }
        });
        dispatch({ type: "protection_plan_success", payload: response?.data?.data });
    } catch (err) {
        dispatch({ type: "protection_plan_failure", payload: err.message });
    }
}

export const paymentResponse = () => async (dispatch) => {
    try {
        dispatch({ type: "payment_response_pending" });
        const response = await axios.get(`${baseurl}/api/payment//get-payment-gateway-response?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "payment_response_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "payment_response_failure", payload: err.message })
    }
}