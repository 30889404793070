import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postChoosedPlan } from "../../services/Data/PostChoosedPlan";
import { LoaderContext } from "../../context/LoaderContext";
import { useContext } from "react";
const Continue = ({ total, currentPlan }) => {


  const { setLoading } = useContext(LoaderContext);
  const navigate = useNavigate();

  const handleContinue = async () => {
    setLoading(true);
    const response = await postChoosedPlan(currentPlan);
    setLoading(false);
    console.log(currentPlan);
    console.log(response);
    if (response && response.status === 200) {
      toast.success("Plan Choose Successfully !");
    } else {
      toast.error("Error", response);
    }
    // console.log(currentPlan);
    // localStorage.setItem("currentPlan", JSON.stringify(currentPlan));
    navigate("/form");
  };

  return (
    <div className="continue-container mt-4">
      <div className="text-center mt-2 ms-2">
        <p className="continue-text">
          <span className="confirm-salary">{total}/</span>
          <br /> When paid annually
        </p>
      </div>
      <div>
        <button className="continue-btn" onClick={handleContinue}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default Continue;
