import { useContext, useEffect, useState } from "react";
import DeclarationModal from "./Modals/DeclarationModal";
import Popup from "./Popup";
import { AccountContext } from "../context/AccountContext";
import { sendOtp, verifyOtp } from "../services/Otp/SendOtp";
import { validateOtp } from "../validations";

import { toast } from "react-toastify";
import { getProposalId } from "../services/Data/GetProposalId";
import { handlePayment } from "../payment/payment";
import { baseurl } from "../constants";
import axios from "axios";
import { LoaderContext } from "../context/LoaderContext";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { choosedPlan, communicationForm, employmentForm, kycForm, personalForm } from "../redux/actions/formsAction";

export default function PriceButton({ durationText }) {
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const [doAccept, setDoAccept] = useState(false);
    const [otp, setOtp] = useState(null);
    const [isOtpSent, setOtpSent] = useState(false);
    const [otpAlert, setOtpAlert] = useState({ state: false });
    const [disabledBtn, setDisabledBtn] = useState(true);
    const [disablePayButton, setDisablePayButton] = useState(true);
    const { setLoading } = useContext(LoaderContext);

    const { user } = useContext(AccountContext);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(employmentForm());
        dispatch(kycForm());
        dispatch(personalForm());
        dispatch(communicationForm());
        dispatch(choosedPlan());
    }, [dispatch])



    const { loading: eLoading, data: eData, status: eStatus } = useSelector(state => state.employmentForm);
    const { loading: kLoading, data: kData, status: kStatus } = useSelector(state => state.kycForm);
    const { loading: pLoading, data: pData, status: pStatus } = useSelector(state => state.personalForm);
    const { loading: cLoading, data: cData, status: cStatus } = useSelector(state => state.communicationForm);
    const { loading: planLoading, data: planData, status: planStatus } = useSelector(state => state.choosedPlan);

    useEffect(() => {
        if (eStatus && kStatus && pStatus && cStatus && planStatus) {
            setDisablePayButton(false);
        }
    }, [eStatus && kStatus && pStatus && cStatus && planStatus])

    useEffect(() => {
        if (doAccept && !modal) {
            handleSentOtp(pData.mobile, user?.userTransactionId)
        }
    }, [doAccept, modal]);

    const handleSentOtp = async (mobile, userTransactionId) => {
        setLoading(true);
        const response = await sendOtp({ mobile, userTransactionId });
        setLoading(false);
        if (response.status === 200) {
            toast.success("Otp sent successfully !");
        } else {
            toast.error("Error while sending otp.")
        }
        console.log(response);
        setOtpSent(true);
    };

    useEffect(() => {
        // requestProposalId();
    }, [])

    const handleClick = async (e) => {

        e.preventDefault();
        const data = {
            consumerMobileNo: "8445840329",
            consumerEmailId: "paraskumar410@gmail.com",
            amount: "1",
            commisionAmount: "0",
            userTransactionId: localStorage.getItem("userTransactionId")
        }

        console.log("this is were api is calling")

        const res = await axios.post(`${baseurl}/api/payment/create-payment`, data)
        console.log(res.status)
        if (res.status === 200) {
            console.log("result", res.data.result)
            const reqJson = {
                features: {
                    enableAbortResponse: res.data.result.enableAbortResponse,
                    enableExpressPay: res.data.result.enableExpressPay,
                    enableInstrumentDeRegistration: res.data.result.enableInstrumentDeRegistration,
                    enableMerTxnDetails: res.data.result.enableMerTxnDetails,
                    enableNewWindowFlow: res.data.result.enableNewWindowFlow,
                    showPGResponseMsg: res.data.result.showPGResponseMsg,
                    showDownloadReceipt: res.data.result.showDownloadReceipt,
                },
                consumerData: {
                    deviceId: res.data.result.deviceId, // possible values "WEBSH1" or "WEBSH2"
                    token:
                        res.data.result.token,
                    returnUrl:
                        res.data.result.returnUrl, // merchant response page URL
                    responseHandler: handleResponse,
                    paymentMode: res.data.result.paymentMode,
                    merchantLogoUrl:
                        res.data.result.merchantLogoUrl, // provided merchant logo will be displayed
                    merchantId: res.data.result.merchantId,
                    currency: res.data.result.currency,
                    consumerId: res.data.result.consumerId,
                    consumerMobileNo: res.data.result.consumerMobileNo,
                    consumerEmailId: res.data.result.consumerEmailId,
                    txnId: res.data.result.txnId, // Unique merchant transaction ID
                    items: [
                        {
                            itemId: res.data.result.itemId,
                            amount: res.data.result.amount,
                            comAmt: res.data.result.comAmt,
                        },
                    ],
                    customStyle: {
                        PRIMARY_COLOR_CODE: res.data.result.PRIMARY_COLOR_CODE, // merchant primary color code
                        SECONDARY_COLOR_CODE: res.data.result.SECONDARY_COLOR_CODE, // provide merchant's suitable color code
                        BUTTON_COLOR_CODE_1: res.data.result.txnId, // merchant's button background color code
                        BUTTON_COLOR_CODE_2: res.data.result.txnId, // provide merchant's suitable color code for button text
                    },
                },
            };

            if (window.$ && window.$.pnCheckout) {
                window.$.pnCheckout(reqJson);
                if (reqJson.features.enableNewWindowFlow) {
                    window.pnCheckoutShared.openNewWindow();
                }
            }
        }


    };
    function handleResponse(res) {
        if (
            typeof res !== 'undefined' &&
            typeof res.paymentMethod !== 'undefined' &&
            typeof res.paymentMethod.paymentTransaction !== 'undefined' &&
            typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined' &&
            res.paymentMethod.paymentTransaction.statusCode === '0300'
        ) {
            // Success block
            alert('Payment successful!');

        } else if (
            typeof res !== 'undefined' &&
            typeof res.paymentMethod !== 'undefined' &&
            typeof res.paymentMethod.paymentTransaction !== 'undefined' &&
            typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined' &&
            res.paymentMethod.paymentTransaction.statusCode === '0398'
        ) {
            // Initiated block
            alert('Payment initiated. Please wait for the confirmation.');
        } else {
            // Error block
            alert('Payment failed. Please try again later.');
        }
    }
    useEffect(() => {


        // const handleClick = (e) => {
        //     e.preventDefault();

        //     const reqJson = {
        //         features: {
        //             enableAbortResponse: true,
        //             enableExpressPay: true,
        //             enableInstrumentDeRegistration: true,
        //             enableMerTxnDetails: true,
        //             enableNewWindowFlow: true, // for hybrid applications, please disable this by passing false
        //         },
        //         consumerData: {
        //             deviceId: 'WEBSH2', // possible values "WEBSH1" or "WEBSH2"
        //             token:
        //                 'c1c89085f939d7ab3e52cc1965f3b69fbd5cd4345e11db2cf20e23a834f3d339a57755500ed20ab4f2cab2bc70d73635e81a0d8e2fdad385cae44b5ce4418d97',
        //             returnUrl:
        //                 'https://www.tekprocess.co.in/MerchantIntegrationClient/MerchantResponsePage.jsp', // merchant response page URL
        //             responseHandler: handleResponse,
        //             paymentMode: 'all',
        //             merchantLogoUrl:
        //                 'https://www.paynimo.com/CompanyDocs/company-logo-vertical.png', // provided merchant logo will be displayed
        //             merchantId: 'L3348',
        //             currency: 'INR',
        //             consumerId: 'c964634',
        //             consumerMobileNo: '9876543210',
        //             consumerEmailId: 'test@test.com',
        //             txnId: '1689929690868', // Unique merchant transaction ID
        //             items: [
        //                 {
        //                     itemId: 'first',
        //                     amount: '10',
        //                     comAmt: '0',
        //                 },
        //             ],
        //             customStyle: {
        //                 PRIMARY_COLOR_CODE: '#45beaa', // merchant primary color code
        //                 SECONDARY_COLOR_CODE: '#FFFFFF', // provide merchant's suitable color code
        //                 BUTTON_COLOR_CODE_1: '#2d8c8c', // merchant's button background color code
        //                 BUTTON_COLOR_CODE_2: '#FFFFFF', // provide merchant's suitable color code for button text
        //             },
        //         },
        //     };

        //     if (window.$ && window.$.pnCheckout) {
        //         window.$.pnCheckout(reqJson);
        //         if (reqJson.features.enableNewWindowFlow) {
        //             window.pnCheckoutShared.openNewWindow();
        //         }
        //     }
        // };

        // Load the jQuery script dynamically
        const script = document.createElement('script');
        script.classList.add("mc-script-payment");
        script.src = 'https://www.paynimo.com/paynimocheckout/client/lib/jquery.min.js';
        script.async = true;
        document.body.appendChild(script);

        // Load the paynimocheckout script dynamically
        const paynimoScript = document.createElement('script');
        paynimoScript.classList.add("mc-script-paynimo");
        paynimoScript.src = 'https://www.paynimo.com/paynimocheckout/server/lib/checkout.js';
        paynimoScript.async = true;
        document.body.appendChild(paynimoScript);

        return () => {
            // Cleanup on component unmount
            if (document.querySelector(".mc-script-payment")) {
                document.body.removeChild(script);
            }
            if (document.querySelector("mc-script-paynimo")) {
                document.body.removeChild(paynimoScript);
            }
        };
    }, []);


    const handleVerifyOtp = async () => {
        const response = await verifyOtp({ mobile: pData.mobile, userTransactionId: user?.userTransactionId, otp });
        console.log(response);
        if (response.status === 200) {
            toast.success("Otp verified");
            handleClose();
            requestProposalId();
        } else {
            toast.error("Otp did not match !")
        }
    }

    const handleOtpChange = (e) => {
        console.log(e.target.value);
        console.log(validateOtp(e.target.value));
        if (validateOtp(e.target.value)) {
            console.log(document.querySelector(".verify-otp"));
            document.querySelector(".verify-otp").value.replace(/[^0-9]/g, '');
            setOtpAlert({ state: false });
            setOtp(e.target.value);
            setDisabledBtn(false);
        } else {
            setOtpAlert({ state: true, variant: "danger", text: "Otp must contain digits only !" })
            setDisabledBtn(true);
        }
    }

    const handleClose = () => {
        setOtpSent(false);
    }

    const requestProposalId = async () => {
        const response = await getProposalId({ personal: pData, communication: cData, employment: eData, kycForm: kData, user, planData });

        console.log(response);
        console.log(response?.data?.result?.result?.proposalId);
        if (response.status === 200) {
            // localStorage.setItem("proposalId", response?.data?.result?.result?.proposalId);
            handlePayment({ name: pData?.name, mobile: pData?.mobile, email: pData?.email, amount: planData?.coverages[0]?.netPrice });
        } else {
            toast.error(response?.data?.result?.result?.message);
            // navigate("/");

        }
    }

    useEffect(() => {
        console.log(disablePayButton);
    }, [disablePayButton]);




    return <><div className="mc-price-button mc-flex">
        <div>
            <div className="mc-subscription-price-wrapper">
                <span className="mc-subscription-price">₹ {Math.floor(planData?.premium?.netAmount)}</span>
                <span>/-</span>
            </div>
            <p>{durationText || "for annual subscription"}</p>
        </div>
        <button className={disablePayButton ? "mc-btn mc-continue-btn mc-flex mc-disabled-btn" : "mc-btn mc-continue-btn mc-flex"} onClick={() => { if (!disablePayButton) setModal(true) }}>
            <span className="mc-btn-text">Pay Now</span>
            <span className="mc-btn-icon"><i class="fa-solid fa-angle-right"></i></span>
        </button>
    </div>
        <DeclarationModal state={modal} onClose={() => { setModal(false) }} setDoAccept={setDoAccept} doAccept={doAccept} />
        <Popup
            state={isOtpSent}
            title={"Please verify OTP"}
            text={`Please verify the otp that we've sent on your mobile XXXXXX${pData?.mobile?.slice(6, 10) || ""}`}
            form={[
                {
                    type: "tel",
                    placeholder: "Enter OTP",
                    maxLength: 4,
                    onChange: handleOtpChange,
                    alert: otpAlert,
                    className: "verify-otp"
                }
            ]}
            cancelButton={{
                text: "Cancel",
                className: "mc-btn mc-cancel-button",
                onClick: handleClose
            }}
            verifyButton={{
                text: "Verify",
                className: !disabledBtn ? `mc-btn mc-verify-button` : `mc-btn mc-verify-button mc-disabled-btn`,
                onClick: handleVerifyOtp
            }}
        />

    </>
}