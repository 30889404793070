import React, { useState } from "react";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const WhatIsNotCovered = () => {
  const [open, setOpen] = useState(false);
  const handleChange = () => {
    setOpen(!open);
  };
  const notCovered = [
    {
      id: 1,
      image: "/images/stepicon.png",
      text: "Volunteer job loss",
    },
    {
      id: 2,
      image: "/images/stepicon.png",
      text: "Fraud or misconduct",
    },
    {
      id: 3,
      image: "/images/stepicon.png",
      text: "Times of wars and conflict",
    },
    {
      id: 4,
      image: "/images/stepicon.png",
      text: "waiting period",
    },
  ];
  return (
    <>
      <div className="card-container-2 w-100">
        <div
          className="sub-card-2 d-flex p-3 align-items-center justify-content-center w-md-75 w-100 text-center"
          onClick={handleChange}
        >
          <div className="not-covred-text">
            <p className=" m-0"> What is not covered</p>
          </div>
          <div className="mx-3 fontawsome-icons">
            <FontAwesomeIcon

              icon={open ? faChevronDown : faChevronRight}
              className="toggle-icon"
            />
          </div>
        </div>
      </div>
      <div>
        {open && (
          <div className="what-is-not-covered">
            {notCovered.map((item) => (
              <div className="d-flex ms-2">
                <img src={item.image} className="not-covered-image me-2"  alt=""/>
                <p className="not-covered-text"> {item.text}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default WhatIsNotCovered;
