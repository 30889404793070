import React from 'react';
import { toast } from 'react-toastify';

const Alert = () => {
  const handleShowToast = () => {
    toast.success('This is a success toast!');
  };

  return (
    <div>
      <button onClick={handleShowToast}>Show Toast</button>
    </div>
  );
};

export default Alert;
