import axios from "axios";
import { baseurl } from "../../constants";

export const getPaymentDetails = async () => {
    try {
        console.log(localStorage.getItem("paymentId"),localStorage.getItem("userTransactionId"),localStorage.getItem("paymentTransactionId"));
        const response = await axios.get(`${baseurl}/api/payment/get-payment-detail/${localStorage.getItem("paymentId")}`, {
            headers: {
                userTransactionId: localStorage.getItem("userTransactionId"),
                paymentTransactionId: localStorage.getItem("paymentTransactionId")
            }
        })
        return response;
    } catch (err) {
        return err.message;
    }
}