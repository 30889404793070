export const validatePassword = (value) => {
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /[0-9]/;

    const hasUppercase = uppercaseRegex.test(value);
    const hasLowercase = lowercaseRegex.test(value);
    const hasNumber = numberRegex.test(value);

    if ((hasUppercase && hasLowercase && hasNumber && value.length >= 8)) {
        return true;
    } else {
        return false;
    }
}

export const validateEmail = (value) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(value)) {
        return false;
    } else {
        return true;
    }
}

export const validateMobile = (value) => {
    value.replace(/\D/g, '');
    if (/^\d{10}$/.test(value)) {
        return true;
    } else {
        return false;
    }
}

export const validateGoogleId = (value) => {
    value.replace(/\D/g, '');
    if (/^\d{21}$/.test(value)) {
        return true;
    } else {
        return false;
    }
}

export const validateName = (name) => {
    const nameRegex = /^[a-zA-Z ]+$/;
    return nameRegex.test(name);
};

export const validatePanNumber = (panNumber) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panRegex.test(panNumber);
}

export const validateOtp = (otp)=>{
    const otpRegex = /^\d{4}$/;
    return otpRegex.test(otp);

}