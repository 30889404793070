import { useEffect } from "react";
import { choosedPlan } from "../redux/actions/formsAction";
import { useDispatch, useSelector } from "react-redux";

export default function BillModal({ smallText, heading }) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(choosedPlan());
    }, [dispatch])

    const { loading, data: planData, status } = useSelector(state => state.choosedPlan);

  

    const items = [
        { text: "Plan cost paid annually", amount: `₹ ${Math.floor(planData?.premium?.grossAmount)} /-` || "₹ NaN" },
        { text: "Taxes", amount: `₹ ${Math.floor(planData?.premium?.tax)} /-` || "₹ NaN" },
        { text: "Total", amount: `₹ ${Math.floor(planData?.premium?.netAmount)} /-` || "₹ NaN" }
    ]
    return <>
        <div className="mc-bill-container">
            <p>{smallText || "Smart"}</p>
            <h2>{heading || "Cost Breakdown"}</h2>
            {
                items.map((item) => {
                    return <div className="mc-flex mc-bill-item">
                        <p className="mc-bill-item-text">{item?.text}</p>
                        <p className="mc-bill-item-amount">{item?.amount}</p>
                    </div>
                })
            }
        </div>
    </>
}