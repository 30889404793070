import axios from "axios";
import { baseurl } from "../../constants";

export const submitPersonalDetailForm = async (data, id) => {
    try {
        console.log(data);
        const form = { ...data, user_transaction_id: id };
        console.log(form);
        const response = await axios.post(`${baseurl}/api/protection/personal-details`, form);
        return response;
    } catch (err) {
        return err.message;
    }
}