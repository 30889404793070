import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { getReduxDateReducer } from "./reducers/demoReducer";
import { getReduxSecondDateReducer } from "./reducers/demoReducerSecond";
import { getChoosedPlanReducer, getCommunicationDetailsReducer, getEmploymentFormDateReducer, getKYCDetailsReducer, getPersonalDetailsReducer, getProposalDataReducer } from "./reducers/formReducers";
import { getPaymentDetailsReducer, getPaymentLedgerReducer, getPaymentResponseReducerReducer, getProtectionPlanReducer } from "./reducers/paymentReducer";

const reducer = combineReducers({
    choosedPlan: getChoosedPlanReducer,
    employmentForm: getEmploymentFormDateReducer,
    kycForm: getKYCDetailsReducer,
    personalForm: getPersonalDetailsReducer,
    communicationForm: getCommunicationDetailsReducer,
    proposalData: getProposalDataReducer,
    paymentResponse:getPaymentResponseReducerReducer,
    paymentLedger: getPaymentLedgerReducer,
    paymentDetails: getPaymentDetailsReducer,
    protectionPlan:getProtectionPlanReducer,
});

const middleware = [thunk];

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;