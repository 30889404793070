import axios from "axios";
import { baseurl } from "../../constants";
import { toast } from "react-toastify";

export const choosedPlan = () => async (dispatch) => {
    try {
        dispatch({ type: "chooses_plan_pending" });
        const response = await axios.get(`${baseurl}/api/protection/get-choosed-plan?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "choosed_plan_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "choosed_plan_failure", payload: err.message });
    }
}

export const employmentForm = () => async (dispatch) => {
    try {
        dispatch({ type: "employment_form_pending" });
        const response = await axios.get(`${baseurl}/api/protection/get-employment-details?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "employment_form_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "employment_form_failure", payload: err.message });
    }
}


export const kycForm = () => async (dispatch) => {
    try {
        dispatch({ type: "kyc_form_pending" });
        const response = await axios.get(`${baseurl}/api/auth/get-pan?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        const responseT = await axios.get(`${baseurl}/api/auth/get-pan-transaction?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "kyc_form_success", payload: { pan: response?.data?.result, transaction: responseT?.data?.result } });
    } catch (err) {
        dispatch({ type: "kyc_form_failure", payload: err.message });
    }
}


export const personalForm = () => async (dispatch) => {
    try {
        dispatch({ type: "personal_form_pending" });
        const response = await axios.get(`${baseurl}/api/protection/get-personal-details?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "personal_form_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "personal_form_failure", payload: err.message });
    }
}

export const communicationForm = () => async (dispatch) => {
    try {
        dispatch({ type: "communication_form_pending" });
        const response = await axios.get(`${baseurl}/api/protection/get-communication-address?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "communication_form_success", payload: response?.data?.result });
    } catch (err) {
        dispatch({ type: "communication_form_failure", payload: err.message });
    }
}


export const proposalData = () => async (dispatch) => {
    try {
        dispatch({ type: "proposal_data_pending" });
        const response = await axios.get(`${baseurl}/api/protection/get-proposal-data?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        dispatch({ type: "proposal_data_success", payload: response?.data?.result[0] });
    } catch (err) {
        dispatch({ type: "proposal_data_failure", payload: err.message });
    }
}
