
export const getPaymentLedgerReducer = (state = {}, action) => {
    switch (action.type) {
        case "payment_ledger_pending":
            return { loading: true, status: 0 }

        case "payment_ledger_success":
            return { loading: false, status: 1, data: action.payload }

        case "payment_ledger_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}

export const getPaymentDetailsReducer = (state = { loading: false, status: 0 }, action) => {
    switch (action.type) {
        case "payment_details_pending":
            return { loading: true, status: 0 }

        case "payment_details_success":
            return { loading: false, status: 1, data: action.payload }

        case "payment_details_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}

export const getProtectionPlanReducer = (state = { loading: false, status: 0 }, action) => {
    console.log(action);
    switch (action.type) {
        case "protection_plan_pending":
            return { loading: true, status: 0 }

        case "protection_plan_success":
            return { loading: false, status: 1, data: action.payload }

        case "protection_plan_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}


export const getPaymentResponseReducerReducer = (state = { loading: false, status: 0 }, action) => {
    console.log(action);
    switch (action.type) {
        case "payment_response_pending":
            return { loading: true, status: 0 }

        case "payment_response_success":
            return { loading: false, status: 1, data: action.payload }

        case "payment_response_failure":
            return { loading: false, status: 0, data: action.payload }

        default: return state;

    }
}