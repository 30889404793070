import React from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';


const SelectTag = ({ options, placeholder, value, loadOptions, ...rest }) => {

    console.log(options);

    return (

        (options && options.length > 0 && typeof options === 'object') ? <Select
            placeholder={placeholder || "Select..."}
            options={options}
            {...rest}
        /> : <AsyncSelect
            cacheOptions
            placeholder={placeholder || "Select..."}
            loadOptions={loadOptions}
            {...rest}
            isSearchable
        />


    );
};

export default SelectTag;
