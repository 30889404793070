import React from 'react';

const BenefitSection2 = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
        <div>
        <p className="plan-benefits-title">
          One Plan to protect <br /> you in case of a job loss
        </p>
      </div>
    
        </div>
        
        <div className="col-md-6">
          <div className='d-flex w-100'>
          <div className="d-flex align-items-center mb-3 w-50">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> 3 Months of salary payment</p></div>
        </div>
        <div className="d-flex align-items-center mb-3 w-50">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> Emergency fund for your basic needs</p></div>
        </div>
          </div>
          <div className='d-flex w-100'>
          <div className="d-flex align-items-center mb-3 w-50">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> 3 Months of salary payment</p></div>
        </div>
        <div className="d-flex align-items-center mb-3 w-50">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> Emergency fund for your basic needs</p></div>
        </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitSection2;
