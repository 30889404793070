import axios from "axios"
import { baseurl } from "../../constants"

export const getPaymentLedger = async (user,txnId,paymentTransactionId,proposalId) => {
    try {
        console.log("ledger",user,txnId,paymentTransactionId,proposalId )
        const response = await axios.post(`${baseurl}/api/payment/ledger`, {
            proposalId,
            txnId,
            userTransactionId: localStorage.getItem('userTransactionId'),
            paymentTransactionId,
        });
        return response;
    } catch (err) {
        return err.message;
    }
}