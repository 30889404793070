import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useContext } from "react";
import { PlanContext } from "../context/PlansContext";
import { AccountContext } from "../context/AccountContext";
import { LoaderContext } from "../context/LoaderContext";
import { useDispatch } from "react-redux";
import { communicationForm, employmentForm, kycForm, personalForm } from "../redux/actions/formsAction";
import { baseurl } from "../constants";

const GetPlans = () => {
  const [plan, setPlan] = useState(null);
  const { setUser } = useContext(AccountContext);
  const { setLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const phoneRegExp = /^[6789][0-9]{9}$/;
  const initialValues = {
    name: "",
    mobile: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("name is required"),
    mobile: Yup.string()
      .required("mobile number is required")
      .matches(
        phoneRegExp,
        "Phone Number should start with 6, 7, 8, 9 and should have 10 digit numbers"
      )
      .min(10, "Please enter 10 digit mobile number")
      .max(10, "Not more than 10 digits"),
  });

  const [salary, setSalary] = useState(25000);
  // const [name, setName] = useState("");
  // const [mobile, setMobile] = useState("");

 
  const [showModal, setShowModal] = useState(false);
  const [planData, setPlanData] = useState([]);
  const { setPlans } = useContext(PlanContext);
  const handleSalaryChange = (e) => {
    const newSalary = e.target.value;
    setSalary(newSalary);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  const handleProgressBarChange = (e) => {
    const newSalary = e.target.value;

    setSalary(newSalary);
  };

  const handleModal = () => {
    setShowModal(true);
  };

  const onSubmit = async (values, { resetForm }) => {
    const data = {
      salary,
      name: values.name,
      mobile: String(values.mobile),
    };
    console.log("data", data);
    setLoading(true);

    await axios
      .post(`${baseurl}/api/protection/quote`, data)
      .then((response) => {
        console.log(response, "response")
        setLoading(false);
        localStorage.setItem("userTransactionId", response.data.result.userData.user_transaction_id);
        handleResetRedux();
        setUser({
          userTransactionId: response.data.result.userData.user_transaction_id
        });
        console.log("response", response);
        console.log(response.data.result.userData.user_transaction_id);
        // console.log("plans are", response.data.result.result.result.plans);
        if (response.status  === 200) {
          setPlanData(response?.data?.result?.result?.result?.plans);
          setPlans(response?.data?.result?.result?.result?.plans);
          // console.log(response?.data?.result?.result?.result?.plans);
          // console.log("chuimui", planData);
          navigate("/plans");
          toast.success("success");
          setShowModal(false);
          setSalary("");
          // localStorage.setItem("salary", salary);
          resetForm();
        } else if(response.status === 201){
          toast.info("Please fill all the details properly and try again.")
          navigate("/");
        }else {
          toast.error("Error");
        }
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast.error("Getting an error, please try again");
      });
  };

  useEffect(() => {
    // console.log(planData);
  }, [planData]);

  const formik = useFormik({
    initialValues,

    validationSchema,

    onSubmit,
  });

  const dispatch = useDispatch();
  
  const handleResetRedux = () => {
    dispatch(employmentForm());
    dispatch(kycForm());
    dispatch(personalForm());
    dispatch(communicationForm());
  }

  return (
    <div className="card custom-card">
      <div className="card-body">
        <div className="d-flex">
          <div className="form-container-titles">
            <p className="get-plans-title">Your monthly gross salary</p>

            <p className="plan-subheading">With all takes & Deductions</p>
          </div>

          <div className="form-group custom-form">
            <div className="input-group light-border">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <img src="/images/rupeeslogo.png" alt="" />
                </span>
              </div>

              <input
                type="number"
                className="form-control custom-form-control"
                value={salary}
                onChange={handleSalaryChange}
                required
              />
            </div>
          </div>
        </div>

        <div>
          <input
            id="salary-range"
            type="range"
            min="0"
            max="100000"
            value={salary}
            onChange={handleProgressBarChange}
          />
        </div>

        <div className="btntext-wrapper">
          <button className="quotes-btn" onClick={handleModal}>
            Starting form Rs 99/- Month
          </button>

          <p className="get-plan-txt">Get plan in 2 Min</p>
        </div>

        {/* Modal */}

        <Modal centered show={showModal}>
          <div className="mc-modal-form-container">
            <h2>Personal Details</h2>

            <div className="mc-input-fields-wrapper">
              <form onSubmit={formik.handleSubmit}>
                <input
                  id="Name"
                  name="name"
                  type="text"
                  className="mb-4"
                  placeholder="*Enter Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />

                {formik.touched.name && formik.errors.name ? (
                  <span className="error-message">{formik.errors.name}</span>
                ) : null}

                <input
                  name="mobile"
                  type="number"
                  className="mb-4"
                  placeholder="*Enter Your Number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile}
                  maxLength={10}
                />

                {formik.touched.mobile && formik.errors.mobile ? (
                  <div className="error-message">{formik.errors.mobile}</div>
                ) : null}
              </form>

              <div className="d-flex">
                <div className="btn-div">
                  {/* <button className="see-all-plans-btn" onClick={handleSubmit}>

                    See All Plans

                  </button> */}

                  <button
                    type="submit"
                    className="see-all-plans-btn"
                    onClick={formik.handleSubmit}
                  >
                    See All Plans
                  </button>
                </div>

                <div className="btn-div ms-4">
                  <button className="cancel-button" onClick={handleCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default GetPlans;
