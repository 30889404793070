import { baseurl } from "../../constants";
import axios from "axios";

export const submitEmploymentForm = async (data) => {
    try {
        console.log(data);
        console.log(data?.pincode?.pincode,"data?.companyName?.pincode",data );
        const form = {
            "employmentTypeId": data.employmentTypeId,
            "employmentTypeName": data.employmentTypeName,
            "employmentTypeDescription": data.employmentTypeDescription,
            "companyName": data?.companyName?.name,
            "companyId": data?.companyName?.id, 
            "companyStatus": data?.companyName?.status,
            "companyPincode": data?.companyName?.pincode?data?.companyName?.pincode:data?.pincode?.pincode,
            "companyAddressPincodeId": data?.pincode?.id.toString(),
            "companyAddressDistrict": data?.pincode?.district,
            "companyAddressCity": data?.pincode?.city,
            "companyAddressLocality": data?.pincode?.locality,
            "companyAddressPincode": data?.companyName?.pincode?data?.companyName?.pincode:data?.pincode?.pincode,
            "companyAddressState": data?.pincode?.state,
            "companyAddressStateCode": data?.pincode?.stateCode,
            "companyAddressStateId": data?.pincode?.stateId.toString(),
            "joiningMonth": data?.monthOfJoining,
            "userTransactionId": data?.userTransactionId
        }

        console.log(form);
        const response = await axios.post(`${baseurl}/api/protection/employment-details`, form);
        return response;
    } catch (err) {
        return err.message;
    }
}