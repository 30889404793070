import { React } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const Navbar = () => {
  const navigate = useNavigate();
  return (
    <nav className="navbar navbar-expand-lg  custom-navbar">
      <div className="container navbar-container">
        <a className="navbar-brand" href="/">
          <img
            src="/images/bp-logo.png"
            width="85px"
            height="67px"
            flex-shrink="0"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse mt-2 mobile-view" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Pages
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/">
                    Page 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Page 2
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Page 3
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="/"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Insurance
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/">
                    Insurance 1
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Insurance 2
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                    Insurance 3
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/">
                Contact us
              </a>
            </li>
            <li><a className="nav-link" href="/">
              <FontAwesomeIcon icon={faSearch} />
            </a></li>
          </ul>
         
          <button className="btn btn-primary ml-lg-2 custom-btn" onClick={()=>{navigate("/")}}>Get a Quote</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
