import { createContext, useState } from "react";

export const AccountContext = createContext();

export default function AccountProvider({ children }) {
    const [user, setUser] = useState(null);

    return <AccountContext.Provider value={{ user, setUser }}>
        {children}
    </AccountContext.Provider>
}