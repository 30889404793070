import React from 'react'

const PlanDownloadButton = ({onClick}) => {
  return (
    <div className='download-plan-div'>
        <div>
            <img src='/images/downloadIcon.png'/>
        </div>
        <div>
            <button className='download-btn' onClick={onClick}>Download</button>
        </div>
    </div>
  )
}

export default PlanDownloadButton