import { useEffect, useState } from "react";
import { baseurl } from "../constants";
import axios from "axios";

const UpdateSalary = () => {
  var salaryChange = localStorage.getItem("salary");
  const [salary, setSalary] = useState(salaryChange);
  const [isSalaryChanged, setIsSalaryChanged] = useState(false);
  const [userTransactionId, setUserTransactionId] = useState();
  

  const handleSalaryChange = (e) => {
    const newSalary = e.target.value;
    setSalary(newSalary);
    setIsSalaryChanged(true);
  };

  const handleProgressBarChange = (e) => {
    const newSalary = e.target.value;
    setSalary(newSalary);
    setIsSalaryChanged(true);
  };

  const handleUpdateSalary = () => {
    // localStorage.setItem("salary", salary);
    setIsSalaryChanged(false);
  };

  useEffect(() => {
    const user_transaction_id = localStorage.getItem('userTransactionId');
    console.log("////", user_transaction_id);
    setUserTransactionId(user_transaction_id);
    console.log("qsb", userTransactionId);

    axios.get(`${baseurl}/api/protection/get-user-details?userTransactionId=${user_transaction_id}`)
        .then(res => {
            console.log("....", res);
            setSalary(res.data?.result[0].salary);
            console.log("fgjlk", res.data?.result[0].salary);
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
}, [userTransactionId]);  


  return (
    <div>
      <div>
        <p className="choose-salary-heading">
          Select your salary, <br /> Choose a plan
        </p>
      </div>
      <div className="row">
        <div className="col-6">
          <p className="monthly-gross-salary mb-1">Your monthly gross salary</p>
          <p className="taxes-text">with all taxes & deductions</p>
        </div>
        <div className="col-6">
          <div className="mx-3">
            <div className="form-group custom-form custom-form-1">
              <div className="input-group light-border">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <img alt="" src="/images/rupeeslogo.png" />
                  </span>
                </div>

                <input
                  type="number"
                  className="form-control custom-form-control bg-white"
                  value={salary}
                  onChange={handleSalaryChange}
                  disabled={true}
                />
              </div>
            </div>

            {/* {isSalaryChanged && (
              <div className="">
              <button className="update-button mt-3" onClick={handleUpdateSalary}>
                Update
              </button>
              </div>
            )} */}

          </div>
        </div>
      </div>
      <div className="mt-4">
        <input
          // id="salary-range"
          className="salary-range-2"
          type="range"
          min={"0"}
          max={"100000"}
          // min={salary || "0"}
          // max={salary || "100000"}
          value={salary}
          onChange={handleProgressBarChange}
          disabled={true}
        />
      </div>
    </div>
  );
};

export default UpdateSalary;
