import { baseurl } from "../constants";
import axios from "axios";
export const handlePayment = async ({ name, email, mobile, amount }) => {


    console.log("amount to be paid ", Math.ceil(amount));
    const data = {
        consumerMobileNo: mobile,
        consumerEmailId: email,
        amount: "1" || Math.ceil(amount),
        commisionAmount: "0",
        userTransactionId: localStorage.getItem("userTransactionId")
    }
    console.log("this is were api is calling");


    console.log(data);
    console.log({
        consumerMobileNo: JSON.parse(localStorage.getItem("personalForm"))?.mobile,
        consumerEmailId: JSON.parse(localStorage.getItem("personalForm"))?.email,
        amount: "1",
        commisionAmount: "0",
        userTransactionId: localStorage.getItem("userTransactionId")
    });

    const res = await axios.post(`${baseurl}/api/payment/create-payment`, data)

    console.log(res.status)

    if (res.status === 200) {

        console.log("result", res.data.result)

        const reqJson = {

            features: {

                enableAbortResponse: res.data.result.enableAbortResponse,

                enableExpressPay: res.data.result.enableExpressPay,

                enableInstrumentDeRegistration: res.data.result.enableInstrumentDeRegistration,

                enableMerTxnDetails: res.data.result.enableMerTxnDetails,

                enableNewWindowFlow: res.data.result.enableNewWindowFlow,

                showPGResponseMsg: res.data.result.showPGResponseMsg,

                showDownloadReceipt: res.data.result.showDownloadReceipt,

            },

            consumerData: {

                deviceId: res.data.result.deviceId, // possible values "WEBSH1" or "WEBSH2"

                token:

                    res.data.result.token,

                returnUrl:

                    res.data.result.returnUrl, // merchant response page URL

                responseHandler: handleResponse,

                paymentMode: res.data.result.paymentMode,

                merchantLogoUrl:

                    res.data.result.merchantLogoUrl, // provided merchant logo will be displayed

                merchantId: res.data.result.merchantId,

                currency: res.data.result.currency,

                consumerId: res.data.result.consumerId,

                consumerMobileNo: res.data.result.consumerMobileNo,

                consumerEmailId: res.data.result.consumerEmailId,

                txnId: res.data.result.txnId, // Unique merchant transaction ID

                items: [

                    {

                        itemId: res.data.result.itemId,

                        amount: res.data.result.amount,

                        comAmt: res.data.result.comAmt,

                    },

                ],

                customStyle: {

                    PRIMARY_COLOR_CODE: res.data.result.PRIMARY_COLOR_CODE, // merchant primary color code

                    SECONDARY_COLOR_CODE: res.data.result.SECONDARY_COLOR_CODE, // provide merchant's suitable color code

                    BUTTON_COLOR_CODE_1: res.data.result.txnId, // merchant's button background color code

                    BUTTON_COLOR_CODE_2: res.data.result.txnId, // provide merchant's suitable color code for button text

                },

            },

        };



        if (window.$ && window.$.pnCheckout) {

            window.$.pnCheckout(reqJson);

            if (reqJson.features.enableNewWindowFlow) {

                window.pnCheckoutShared.openNewWindow();

            }

        }

    }





};

function handleResponse(res) {

    if (

        typeof res !== 'undefined' &&

        typeof res.paymentMethod !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined' &&

        res.paymentMethod.paymentTransaction.statusCode === '0300'

    ) {

        // Success block
        // window.open("http://localhost:3001/");
        alert('Payment successful!');



    } else if (

        typeof res !== 'undefined' &&

        typeof res.paymentMethod !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction !== 'undefined' &&

        typeof res.paymentMethod.paymentTransaction.statusCode !== 'undefined' &&

        res.paymentMethod.paymentTransaction.statusCode === '0398'

    ) {

        // Initiated block

        alert('Payment initiated. Please wait for the confirmation.');

    } else {

        // Error block

        alert('Payment failed. Please try again later.');

    }

}