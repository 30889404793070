import axios from "axios";

export const getRedux = () => async (dispatch) => {
    try {
        dispatch({ type: 0, payload: { arr: [{ name: "paras" }] } })
        // const response  = awiat axios.get 
        dispatch({ type: 1, payload: { arr: [{ name: "paras" }] } })
    } catch (err) {
        dispatch({ type:2, payload: err.message });
    }
}
export const getReduxSecond = () => async (dispatch) => {
    try {
        dispatch({ type: 1, payload: { arr: [{ name: "paras" }] } })
    } catch (err) {
        dispatch({ type:2, payload: err.message });
    }
}