import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-container">
      <div className="faq-item" onClick={toggleAnswer}>
        <div className="faq-question" onClick={toggleAnswer}>
          {question}
        </div>
        <FontAwesomeIcon onClick={toggleAnswer}
          icon={isOpen ? faChevronDown : faChevronRight}
          className="toggle-icon"
          
        />
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

export default FAQItem;
