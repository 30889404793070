import axios from "axios";
import { baseurl } from "../../constants";

export const getPinCode = async (pin) => {
    try {
        console.log(pin);
        const response = await axios.get(`${baseurl}/api/protection/pincode/${pin}`);
        if (response.data.success) {
            return response.data.result.data?.map((item) => { return { label: `${item.locality}, ${item.district}, ${item.city}, ${item.pincode}`, value: item, name: "pincode" } })
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}