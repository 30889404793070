import axios from "axios";
import { baseurl } from "../../constants";

export const submitKycForm = async (data, id) => {
    try {
        console.log(data);
        const form = {
            pan: data.panNumber,
            user_transaction_id: id
        }
        console.log(form);
        const response = await axios.post(`${baseurl}/api/auth/pan`, form)
        return response;
    } catch (err) {
        return err.message;
    }
}