import React, { useContext, useEffect, useState } from "react";
import PaymentSuccess from "../components/PaymentSuccess";
import Navbar from "../components/Navbar"
import { LoaderContext } from "../context/LoaderContext";
import OrderDetails from "../components/OrderDetails";
import PlanDownloadButton from "../components/PlanDownloadButton";
import { AccountContext } from "../context/AccountContext";
import { getPaymentResponse } from "../services/Payments/PaymentResponse";
import { getPaymentLedger } from "../services/Payments/PaymentLegder";
import { getPaymentDetails } from "../services/Payments/PaymentDetail";
import { toast } from "react-toastify";
import { getProtectionPlan } from "../services/Payments/ProtectionPlan";
import { getProtectionPlanDownload } from "../services/Payments/ProtectionPlanDownload";
import { useDispatch, useSelector } from "react-redux";
import { choosedPlan, communicationForm, employmentForm, personalForm, proposalData } from "../redux/actions/formsAction";
import { paymentDetails, paymentLedger, paymentResponse, protectionPlan } from "../redux/actions/paymentAction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ProtectionPlanDownload = () => {
  const { setLoading } = useContext(LoaderContext);
  const { user } = useContext(AccountContext);
  let [isLedgerPosted, setIsLedgerPosted] = useState(0);


  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(personalForm());
    dispatch(employmentForm());
    dispatch(personalForm());
    dispatch(communicationForm());
    dispatch(choosedPlan());
    dispatch(proposalData());
    dispatch(paymentResponse());
    dispatch(paymentLedger());
  }, [dispatch, isLedgerPosted]);





  const { loading: pLoading, data: pData, status: pStatus } = useSelector(state => state.personalForm);


  const { loading: prpLoading, data: prpData, status: prpStatus } = useSelector(state => state.proposalData);
  const { loading: prLoading, data: prData, status: prStatus } = useSelector(state => state.paymentResponse);
  const { loading: plLoading, data: plData, status: plStatus } = useSelector(state => state.paymentLedger);
  const { loading: pdLoading, data: pdData, status: pdStatus } = useSelector(state => state.paymentDetails);
  const paymentDetailsContainer = useSelector(state => state.paymentDetails);
  const { loading: ppLoading, data: ppData, status: ppStatus } = useSelector(state => state.protectionPlan);

  useEffect(() => {
    if (prStatus) {
      dispatch(paymentLedger());
    }
  }, [prLoading]);

  useEffect(() => {
    if (plStatus && plData[0].payment_ledgers_result) {
      console.log("dispatching payment details with ", plData[0].payment_ledgers_result?.payment_id, plData[0]?.payment_response.payment_transaction_id);
      dispatch(paymentDetails(plData[0].payment_ledgers_result?.payment_id, plData[0]?.payment_response.payment_transaction_id));
    }
  }, [plLoading, plStatus, plData]);

  useEffect(() => {
    if (pdStatus && pStatus) {
      dispatch(protectionPlan(pdData?.paymentDetail?.result?.protectionPlanNo, pData?.mobile));
    }
  }, [pdLoading, pLoading, pdStatus, pStatus]);

  useEffect(() => {
    console.log(pdData?.paymentDetail?.result?.protectionPlanNo, pdStatus);
    console.log(paymentDetailsContainer);
  }, [pdLoading])





  const handlePaymentResponse = async () => {
    setLoading(true);
    const response = await getPaymentResponse(user);
    if (response && response?.data?.success) {
      await setPaymentResponseRedux();
      // localStorage.setItem("txnId", response?.data?.result?.clnt_txn_ref);
      // localStorage.setItem("paymentTransactionId", response?.data?.result?.payment_transaction_id);
      await handlePaymentLedger();
      await setPaymentDetailRedux();


    }
    return response;
  }


  const setPaymentResponseRedux = async () => {
    console.log("setting payment response in redux");
    return new Promise((resolve, reject) => {
      dispatch(paymentResponse());
      resolve(true);
    })
  };

  const setPaymentDetailRedux = async () => {
    console.log("setting payment details in redux");
    return new Promise((resolve, reject) => {
      if (plStatus) {
        dispatch(paymentDetails(plData[0].payment_ledgers_result?.payment_id, plData[0]?.payment_response.payment_transaction_id));
        resolve(true);
      }
    })
  };

  const setPaymentLedgerInRedux = async () => {
    console.log("setting payment ledger in redux");
    return new Promise((resolve, reject) => {
      dispatch(paymentLedger());
      resolve(true);
    })
  }
  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  const handlePaymentLedger = async () => {
    if (prStatus && plStatus && prpStatus) {
      setLoading(true);
      const response = await getPaymentLedger(user, prData?.clnt_txn_ref, prData?.payment_transaction_id, prpData?.proposal_result?.proposal_id);
      // console.log(response);
      // localStorage.setItem("paymentId", response?.data?.data?.response?.result?.paymentId);
      setLoading(false);
      setIsLedgerPosted(isLedgerPosted++);
      if (response.status === 200 || response.status === 201) {
        await setPaymentLedgerInRedux();
      } else if (response.status === 201) {
        Swal.fire({
          title: "Thank you",
          text: response?.data?.result?.result?.message || "Your purchase has been done, out team will reach out to you soon.",
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: 'Go to Home Page',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/');
          }
        });
    } else {
      toast.error("Error while payment ledger");
    }
  }
}

useEffect(() => {
  if (prStatus && prpStatus) {
    handlePaymentLedger();
  }
}, [prStatus, prpStatus])


// const handlePaymentDetails = async () => {
//   setLoading(true);
//   const response = await getPaymentDetails();
//   if (response.status === 200) {
//     handleProtectionPlan();
//   } else {
//     toast.error("Error while fetching payment details");
//   }
//   setLoading(false);
// }

// const handleProtectionPlan = async () => {
//   setLoading(true);
//   const response = await getProtectionPlan(pData);
//   if (response.status !== 200) {
//     toast.error('Failed to fetch protection plan');
//   }
//   setLoading(false);
// }

const handleProtectionPlanDownload = async () => {
  try {


    if (ppData) {
      setLoading(true);
      const response = await getProtectionPlanDownload(ppData?.response?.result?.data[0].protectionPlanId, ppData?.userData?.protection_plans_transaction_id, pData?.mobile);
      setLoading(false);
      if (response.status === 200) {
        window.open(response?.data?.result?.pdfLink, "_blank");
      } else {
        toast.error("Error while downloading plan");
      }
    } else {
      toast.info(`No Protection Plan Found`);
    }
  } catch (err) {
    toast.info(err.message);
  }
}

useEffect(() => {
  if (!ppData) {
    console.log("handling payment response");
    handlePaymentResponse();
  }
}, []);

useEffect(() => {
  setLoading(true);
  if (!ppLoading) {
    setLoading(false);
  } else {
    setLoading(true);
  }
}, [ppLoading])








return (
  <div>
    <Navbar />
    <div className="main-component">
      <PaymentSuccess />
      <OrderDetails details={ppData} />
      <PlanDownloadButton onClick={handleProtectionPlanDownload} />
    </div>
  </div>
);
};

export default ProtectionPlanDownload;
