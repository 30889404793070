import axios from "axios"
import { baseurl } from "../../constants"

export const getCompanies = async (e) => {
    try {
        console.log("Searching for company" + e);
        const response = await axios.get(`${baseurl}/api/protection/company?companyName=${e}&companyId=`);
        console.log(response);
        if (response.data.success) {
            return response.data.result.result.data?.map((item) => { return { label: item.name, value: item, name: "companyName" } });
        } else {
            return [];
        }
    } catch (err) {
        console.log("Error while fetching companies" + err.message);
        return [];
    }
}