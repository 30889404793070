// import {React,  useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import FAQItem from "./FAQItem";
const QuestionAndAnswer = () => {
    
  // const [activeQuestion, setActiveQuestion] = useState(null);
  // const handleQuestionClick = (questionIndex) => {
  //   setActiveQuestion(questionIndex === activeQuestion ? null : questionIndex);
  // };

  const faqItems = [
    {
        id: 1,
      question:
        "What amount will I get as the take home salary after filling a claim?",
      answer:
        "After filing a claim, you will receive your plan limit or the average of your last three months salary, whichever is less, as your take-home salary. A 5% TDS will also be applied.",
    },
    {
        id: 2,
      question: "How does the placement concierge help me?",
      answer: "Answer 2",
    },
    {
        id: 3,
      question: "Is this an insurance policy?",
      answer: "Answer 3",
    },
    {
        id: 4,
      question: "What happens if you are forced to resign?",
      answer: "Answer 4",
    },
  ];
  
  return (
    <div className="d-flex align-items-center  question-container mt-5">
      <div className="question-text-container">
        <p className="question-text">Have a <br/> question?</p>
      </div>
      <div class="vertical-line"></div>
     <div>
     <div className="container animate-dropdown">
      {faqItems.map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}
    </div>
     </div>
    </div>
  );
};

export default QuestionAndAnswer;
