import { baseurl } from "../../constants";
import axios from "axios";

export const getProtectionPlanDownload = async (protectionPlanId,protectionPlanTransactionId,mobile) => {
    try {
        const response = await axios.get(`${baseurl}/api/protection/download-protection-plan?protectionPlanId=${protectionPlanId}&mobile=${mobile}&userTransactionId=${localStorage.getItem("userTransactionId")}&protectionPlansTransactionId=${protectionPlanTransactionId}`);
        return response;
    } catch (err) {
        return err.message;
    }
}