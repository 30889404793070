import axios from "axios";
import { baseurl } from "../../constants";

export const sendOtp = async (data) => {
    try {
        console.log(data);
        const response = await axios.post(`${baseurl}/api/auth/send-otp`, data);
        return response;
    } catch (err) {
        return err.message;
    }
}

export const verifyOtp = async (data) => {
    try {
        const response = await axios.post(`${baseurl}/api/auth/verify-otp`, data);
        return response;
    } catch (err) {
        return err.message;
    }
}