import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AccountProvider from './context/AccountContext';
import { LoaderProvider } from './context/LoaderContext';

// redux configuration
import { Provider } from "react-redux";
import store from "./redux/store";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoaderProvider>
        <AccountProvider>
          <App />
        </AccountProvider>
      </LoaderProvider>
    </Provider>
  </React.StrictMode>
);
