import React from "react";

const ProtectionNeed = () => {
  const needSteps = [
    {
      id: 1,
      image: "/images/stepicon.png",
      text: "Company shut down",
    },
    {
      id: 2,
      image: "/images/stepicon.png",
      text: "Acquisition or merger",
    },
    {
      id: 3,
      image: "/images/stepicon.png",
      text: "Company relocation",
    },
    {
      id: 4,
      image: "/images/stepicon.png",
      text: "Changed campany focus",
    },
  ];
  return (
    <div>
      <div className="">
        <p className="plan-benefits-title mb-4">
          Protection when you
          <br /> need it the most
        </p>
      </div>
      <div>
        {
            needSteps.map((steps)=>(
                <div className="d-flex">
                    <img src={steps.image} className="need-img" alt=""/>
                    <p>{steps.text}</p>
                </div>
            ))
        }
      </div>
    </div>
  );
};

export default ProtectionNeed;
