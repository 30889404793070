import { React, useContext, useEffect } from 'react';
import GetPlans from "../components/GetPlans";
import Navbar from "../components/Navbar";
import PlanBenefits from "../components/PlanBenefits";
import QuestionAndAnswer from "../components/Question";
import Procedure from "../components/Procedure";
import TermAndCondition from "../components/TermAndCondition";
import BenefitSection2 from "../components/BenefitsSection2";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import { LoaderContext } from "../context/LoaderContext";
import { getRedux } from '../redux/actions/demoAction';
import { useDispatch, useSelector } from 'react-redux';

export default function Home() {
  const { setLoading } = useContext(LoaderContext);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);

  const dataFromRedux = useSelector(state => state);

  useEffect(() => {
    console.log(dataFromRedux);
  }, dataFromRedux)


  useEffect(() => {
    dispatch(getRedux());
  }, [dispatch]);



  return <>
    <Navbar />
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-6 plan-benefits-parent">
          <div>
            <PlanBenefits />
          </div>
        </div>
        <div className="col-md-6 ">
          <div className="d-flex justify-content-end">
            <GetPlans />
          </div>
        </div>
      </div>
      <div className="mt-4 mb-5">
        <hr />
      </div>
      <div className="plan-benefits-parent">
        <BenefitSection2 />
      </div>
      <div className="mt-4 mb-5">

      </div>
      <div className="d-flex-column align-items-center justify-content-center">
        <div className="">
          <Procedure />
        </div>
        <div>
          <QuestionAndAnswer />
        </div>
        <div className="mt-5 mb-5">
          <TermAndCondition />
        </div>
      </div>


    </div>
    <div > <Newsletter /></div>
    <div>
      <Footer />
    </div>
  </>;
}