import React from "react";

const Procedure = () => {
  return (
    <div className="procedure-container">
      <div>
        <p className="procedure-heading">
          Make a claim in <br /> 3 simple steps!
        </p>
      </div>
      <div className="d-flex align-items-center mr-3">
        {/* first step */}
        <div className="row">
        <div className="col-sm-4">
          <div>
            <p className="sr-no m-0 p-0">1.</p>
          </div>
          <div>
            <p className="procedure-title m-0 p-0">Tell us what happened</p>
          </div>
          <div>
            <p className="procedure-description m-0 p-0">
              Answer the simple questions, <br /> so we can understand the
              <br /> event
            </p>
          </div>
        </div>
        {/* step 2 */}
        <div className=" col-sm-4 col-">
          <div>
            <p className="sr-no m-0 p-0">2.</p>
          </div>
          <div>
            <p className="procedure-title m-0 p-0">Upload documentation</p>
          </div>
          <div>
            <p className="procedure-description m-0 p-0">
              Upload your joining letter, layoff <br /> proof and last 3 months
              <br /> payslips
            </p>
          </div>
        </div>
        {/* step 3 */}
        <div className=" col-sm-4">
          <div>
            <p className="sr-no m-0 p-0">3.</p>
          </div>
          <div>
            <p className="procedure-title m-0 p-0">File a claim</p>
          </div>
          <div>
            <p className="procedure-description m-0 p-0">
              Done! we will take <br /> care of
              <br /> everything else
            </p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Procedure;
