import React from "react";

const PaymentSuccess = ({ response }) => {
  return (
    (response && response === "failed") ? <div className="success-container">
      <img src="https://static.vecteezy.com/system/resources/thumbnails/004/968/453/small/failed-to-make-payment-by-credit-card-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-vector.jpg" className="success-image" alt="" />
      <p className="success-text">Your purchase has been failed. </p>
    </div>
      :
      (response && response === "pending") ?
        <div className="success-container">
          <img src="/images/pending-logo.avif" className="success-image" alt="" style={{ maxWidth: 250 }} />
          <p className="success-text">Your payment have been initiated, we'll reach out to you soon.</p>
        </div>
        :
        (response && response === "companyAdded") ?
          <div className="success-container">
            <img src="https://img.freepik.com/premium-vector/green-check-mark-icon-symbol-logo-circle-tick-symbol-green-color-vector-illustration_685751-503.jpg" className="success-image company-add-image" alt="" style={{ maxWidth: 250 }} />
            <h2 className="mc-thank-you">Thank you</h2>
            <p className="success-add-company-text">Thank you for request. We will notify you if your company meets the eligibility criteria and is successfully added to our database.</p>
          </div>
          :
          <div className="success-container">
            <img src="/images/successLogo.png" className="success-image" alt="" />
            <p className="success-text">Your have successfully purchased <br /> Job Insurance policy. </p>
          </div>
  );
};

export default PaymentSuccess;
