import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faLocation,
  faMailReply,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="bg-dark text-light">
      <div className="container">
        <div className="row justify-content-center mx-3">
          <div className="col-lg-3 col-md-6 mt-4">
            <h3>
              <FontAwesomeIcon icon={faHome} className="me-2" />
              Assurena
            </h3>
            <ul className="list-unstyled">
              <li>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </li>
              <li>
                <p>
                  <FontAwesomeIcon icon={faLocation} className="me-2" />
                  286, chandler drive, NJ 08003
                </p>
              </li>
              <li>
                <p>
                  <FontAwesomeIcon icon={faMailReply} className="me-2" />
                  admin@assurena.com
                </p>
              </li>
              <li>
                <p>
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  +01234567890
                </p>
              </li>
            </ul>
            <div className="d-flex">
              <a>
                <img src="/images/twitter.png" className="social-images" />
              </a>
              <a>
                <img src="/images/facebook.png" className="social-images" />
              </a>
              <a>
                <img src="/images/linkedin.png" className="social-images" />
              </a>
              <a>
                <img src="/images/instagram.png" className="social-images" />
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 mt-4 footer-services">
            <h3>Services</h3>
            <ul className="list-unstyled services-list">
              <li>
                <a href="/">Auto Insurance</a>
              </li>
              <li>
                <a href="/">Health Insurance</a>
              </li>
              <li>
                <a href="/">Life Insurance</a>
              </li>
              <li>
                <a href="/">Home Insurance</a>
              </li>
              <li>
                <a href="/">Travel Insurance</a>
              </li>
              <li>
                <a href="/">Business Insurance</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mt-4">
            <h3>Latest News</h3>
            <ul className="list-unstyled">
              <li>
                <p>The risk and reward of empowered customer</p>
              </li>
              <li>
                <p className="footer-small-text">October 5, 2020</p>
              </li>
              <li>
                <p>A few realization about natural disaster</p>
              </li>
              <li>
                <p className="footer-small-text">October 5, 2020</p>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6 mt-4">
            <h3 className="openinghoursheading">Opening Hours</h3>
            <ul className="list-unstyled openhours">
              <li className="">
                <div className="d-flex timing-line">
                  <div>Monday</div>
                  <div className="line"></div>
                  <div>9:00-7:00</div>
                </div>
              </li>
              <li>
                <div className="d-flex timing-line">
                  <div>Tuesday</div>
                  <div className="line"></div>
                  <div>9:00-7:00</div>
                </div>
              </li>
              <li>
                <div className="d-flex timing-line">
                  <div>Wednesday</div>
                  <div className="line"></div>
                  <div>9:00-7:00</div>
                </div>
              </li>
              <li>
                <div className="d-flex timing-line">
                  <div>Thursday</div>
                  <div className="line"></div>
                  <div>9:00-7:00</div>
                </div>
              </li>
              <li>
                <div className="d-flex timing-line">
                  <div>Friday</div>
                  <div className="line"></div>
                  <div>9:00-5:00</div>
                </div>
              </li>
              <li>
                <div className="d-flex timing-line">
                  <div>Saturday</div>
                  <div className="line"></div>
                  <div>Closed</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
