import React, { useContext, useEffect, useState } from "react";
import { submitEmploymentForm } from '../services/Forms/EmploymentForm';
import { submitKycForm } from '../services/Forms/KycForm';
import { validateEmail, validateMobile, validateName, validatePanNumber } from '../validations';
import { submitPersonalDetailForm } from '../services/Forms/PersonalDetailsForm';
import { submitCommunicationForm } from '../services/Forms/CommunicationForm';
import { getCompanies } from '../services/Data/LoadCompanies';
import { getPinCode } from '../services/Data/GetPinCode';
import { requestAddCompany } from '../services/Forms/AddCompany';
import { AccountContext } from '../context/AccountContext';
import { toast } from 'react-toastify';
import Popup from './Popup';
import { LoaderContext } from "../context/LoaderContext";
import { useDispatch } from "react-redux";
import { personalForm } from "../redux/actions/formsAction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function FormOpener({ text, active, id, ifFilled, updateParentState, updateData }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [modal, setModal] = useState(false);
    const [activeModal, setActiveModal] = useState(null);
    const [employmentForm, setEmploymentForm] = useState({ employmentTypeId: "permanent", employmentTypeName: "Full Time", employmentTypeDescription: "Full-time employment means working a set number of hours, and being considered a regular employee." });
    const [kycForm, setKycForm] = useState({});
    const [personalDetails, setPersonalDetails] = useState({});
    const [communicationDetails, setCommunicationDetails] = useState({});
    const [activeGender, setActiveGender] = useState(null);
    const [isDisabledBtn, setDisabledBtn] = useState([false, false, false, false]);
    const [emailAlert, setEmailAlert] = useState(null);
    const [mobileAlert, setMobileAlert] = useState(null);
    const [nameAlert, setNameAlert] = useState(null);
    const [addCompany, setAddCompany] = useState(false);
    const [addCompanyForm, setAddCompanyForm] = useState({});
    const [companyAddressOption, setCompanyAddressOption] = useState([]);
    const { user } = useContext(AccountContext);
    const { setLoading } = useContext(LoaderContext);

    //setting form states if they are filled and saved
    useEffect(() => {
        if (ifFilled) {
            if (id === 1) {
                // console.log(ifFilled);
                if (ifFilled[1]?.value) {
                    setEmploymentForm({ ...employmentForm, companyName: ifFilled[1]?.value });
                }
                if (ifFilled[2]?.value) {
                    setEmploymentForm({ ...employmentForm, pincode: ifFilled[2]?.value, companyName: ifFilled[1]?.value });
                }
                if (ifFilled[3]?.value) {
                    setEmploymentForm({ ...employmentForm, monthOfJoining: ifFilled[3]?.value, companyName: ifFilled[1]?.value, pincode: ifFilled[2]?.value });
                }
            }
            if (id === 2) {
                // console.log(ifFilled);
            }
            if (id === 3) {
                console.log(ifFilled);
                if (ifFilled[0]?.value) {
                    setPersonalDetails({ ...personalDetails, name: ifFilled[0]?.value })
                }
                if (ifFilled[0]?.value && ifFilled[1]?.value) {
                    setPersonalDetails({ ...personalDetails, name: ifFilled[0]?.value, dob: ifFilled[1]?.value })
                }
                if (ifFilled[0]?.value && ifFilled[1]?.value) {
                    setPersonalDetails({ ...personalDetails, name: ifFilled[0]?.value, dob: ifFilled[1]?.value })
                }
                if (ifFilled[0]?.value && ifFilled[1]?.value || ifFilled[2]?.value) {
                    setPersonalDetails({ ...personalDetails, name: ifFilled[0]?.value, dob: ifFilled[1]?.value, email: ifFilled[2]?.value })
                }
                if (ifFilled[0]?.value && ifFilled[1]?.value && ifFilled[2]?.value || ifFilled[3]?.value) {
                    setPersonalDetails({ ...personalDetails, name: ifFilled[0]?.value, dob: ifFilled[1]?.value, email: ifFilled[2]?.value, mobile: ifFilled[3]?.value })
                }
                if (ifFilled[0]?.value && ifFilled[1]?.value && ifFilled[2]?.value || ifFilled[3]?.value || ifFilled[4]?.value) {
                    setPersonalDetails({ ...personalDetails, name: ifFilled[0]?.value, dob: ifFilled[1]?.value, email: ifFilled[2]?.value, mobile: ifFilled[3]?.value, gender: ifFilled[4]?.value })
                }

            }
            if (id === 4) {
                // console.log(ifFilled);
            }
        }
    }, [ifFilled])

    useEffect(() => {
        if (communicationDetails.pincode) {
            setCommunicationDetails({ ...communicationDetails, state: communicationDetails.pincode.state })
        }
        console.log(communicationDetails);
        if (Object.values(communicationDetails).filter(item => { if (item) return item }).length >= 3) {
            setDisabledBtn([false, false, false, true]);
        }
    }, [communicationDetails]);

    useEffect(() => {
        console.log(addCompanyForm);
    }, [addCompanyForm]);


    const handleClose = () => {
        setModal(false);
        setActiveModal(null);
    }
    const handleOpen = () => {
        if (active)
            setActiveModal(id);
    }


    const handleEmploymentForm = async (e) => {
        if (e.target) {
            setEmploymentForm({ ...employmentForm, [e.target.name]: e.target.value });
        } else {
            setEmploymentForm({ ...employmentForm, [e.name]: e.value })
        }
        if (Object.values(employmentForm).filter(item => { if (item) return item }).length >= 5) {
            setDisabledBtn([true, false, false, false]);
        }
    }
    let timerId;
    const loadCompanies = async (e) => {
        clearTimeout(timerId);
        return new Promise((resolve) => {
            timerId = setTimeout(async () => {
                const response = await getCompanies(e);
                if (response && response.length === 0) setAddCompany(true);
                resolve(response);
            }, 1000);
        });
    };


    const loadPincode = async (e) => {
        const response = await getPinCode(e);
        return response;
    }

    const handleEmploymentFormSubmit = async () => {
        if (isDisabledBtn[0]) {
            const form = {
                ...employmentForm,
                userTransactionId: user?.userTransactionId
            }
            setLoading(true);
            const response = await submitEmploymentForm(form);
            setLoading(false);
            if (response && response.status === 200) {
                // localStorage.setItem("employmentForm", JSON.stringify(employmentForm));
                updateParentState();
                toast.success("Employment Details Added Successfully");
                updateData();
            } else {
                toast.error("Error occured");
            }
            handleClose();
        }
    }

    const handleKycForm = (e) => {
        console.log(e.target.value);
        console.log(validatePanNumber(e.target.value));
        if (validatePanNumber(e.target.value)) {
            setKycForm({ panNumber: e.target.value });
            setDisabledBtn([false, true, false, false]);
        } else {
            setKycForm({})
            setDisabledBtn([false, false, false, false]);
        }
        // if (Object.values(kycForm).filter(item => { if (item) return item }).length >= 1) {
        //     console.log("ho raha hai");

        //     // if (e.target.value.length === 10) 
        //     // else setDisabledBtn([false, false, false, false]);
        // }
    }

    const handleKYCFormSubmit = async () => {
        if (isDisabledBtn[1]) {
            setLoading(true);
            const response = await submitKycForm(kycForm, user?.userTransactionId);
            setLoading(false);
            if (response && response.status === 200) {
                toast.success("Pan Details added successfully !");
                const data = response?.data?.result?.data?.data;
                console.log(data);
                const obj = {
                    name: data?.full_name,
                    dob: data?.dob,
                    email: data?.email,
                    gender: data?.gender,
                    mobile: data?.phone_number,
                    userTransactionId: localStorage.getItem("userTransactionId")
                }
                console.log(obj);
                const responseP = await submitPersonalDetailForm(obj, localStorage.getItem("userTransactionId"));
                if (responseP.status === 200) {
                    dispatch(personalForm());
                    setTimeout(() => {
                        toast.success("Personal Details fetched successfully !");
                    }, 1000);
                }
                updateData();
            } else if (response?.status === 201) {
                console.log(response);
                updateData();
            } else {
                toast.error("Error Occured");
            }
            handleClose();
        }

    }

    const handlePersonalDetailForm = (e) => {
        if (e.target.name === "email") {
            if (validateEmail(e.target.value)) {
                setPersonalDetails({ ...personalDetails, email: e.target.value });
                setEmailAlert({ state: false })
            }
            else
                setEmailAlert({ state: true, variant: "danger", text: "Please provide valid email address." })
        } else if (e.target.name === "name") {
            if (validateName(e.target.value)) {
                setNameAlert({ state: false })
                setPersonalDetails({ ...personalDetails, name: e.target.value });

            }
            else {
                setDisabledBtn([false, false, false, false]);
                setNameAlert({ state: true, variant: "danger", text: "Please provide proper name." });
            }
        } else if (e.target.name === "mobile") {
            if (validateMobile(e.target.value)) {
                setMobileAlert({ state: false })
                setPersonalDetails({ ...personalDetails, mobile: e.target.value });

            }
            else {
                setDisabledBtn([false, false, false, false]);
                setMobileAlert({ state: true, variant: "danger", text: "Please provide valid mobile number" });
            }
        } else {
            setPersonalDetails({ ...personalDetails, [e.target.name]: e.target.value });
        }
        if (Object.values(personalDetails).filter(item => { if (item) return item }).length >= 3) {
            setDisabledBtn([false, false, true, false]);
        }
    }

    const handleGenderChange = (e) => {
        console.log("gender", e);
        if (e == 1) {
            setPersonalDetails({ ...personalDetails, gender: "Male" });
        } else if (e === 2) {
            setPersonalDetails({ ...personalDetails, gender: "Female" });
        } else {
            setPersonalDetails({ ...personalDetails, gender: "Others" });
        }
        setActiveGender(e);
    }

    const handlePersonalDetailsFormSubmit = async () => {
        if (isDisabledBtn[2]) {
            setLoading(true);
            const response = await submitPersonalDetailForm(personalDetails, user?.userTransactionId);
            setLoading(false);
            console.log(response);
            if (response && response.status === 200) {
                toast.success("Personal Details Added Successfully !");
                // localStorage.setItem("personalForm", JSON.stringify(personalDetails));
                updateData();
            } else {
                toast.error('Request Unsuccessful !');
            }
            handleClose();
        }
    }

    const handleCommunicationForm = (e) => {
        console.log(Object.values(communicationDetails).filter(item => { if (item) return item }));

        if (e.target) {
            setCommunicationDetails({ ...communicationDetails, [e.target.name]: e.target.value })
        } else {
            setCommunicationDetails({ ...communicationDetails, [e.name]: e.value });
        }
        if (Object.values(communicationDetails).filter(item => { if (item) return item }).length >= 3) {
            setDisabledBtn([false, false, false, true]);
        }
        console.log(communicationDetails);
    }

    const handleCommunicationFormSubmit = async (e) => {
        if (isDisabledBtn[3]) {
            setLoading(true);
            const response = await submitCommunicationForm(communicationDetails, user?.userTransactionId);
            setLoading(false);
            console.log(response);
            if (response && response.status === 200) {
                // localStorage.setItem("communicationForm", JSON.stringify(communicationDetails));
                toast.success('Date Added Successfully !');
                updateData();
            } else {
                toast.error('Error occured');
            }
            handleClose();
        }
    }

    const handleAddCompany = () => {
        handleClose();
        setActiveModal(5);
    }

    const handleAddCompanyForm = async (e) => {
        if (!e.target) {
            setAddCompanyForm({ ...addCompanyForm, companyPinCode: e.value })
        } else {
            setAddCompanyForm({ ...addCompanyForm, [e.target.name]: e.target.value })
        }

        if (Object.values(addCompanyForm).filter(item => { if (item) return item }).length > 0) {
            setDisabledBtn([false, false, false, false, true]);
        }
    }

    const handleAddCompanyFormSubmit = async (e) => {
        if (isDisabledBtn[4]) {
            setLoading(true);
            const response = await requestAddCompany(addCompanyForm);
            setLoading(false);
            if (response && response.status === 200) {
                toast.success('Request Submitted Successfully!');
                // Swal.fire({
                //     title: "Thank you.",
                //     text: "Thank you for request. We will notify you if your company meets the eligibility criteria and is successfully added to our database",
                //     icon: 'success',
                //     showCancelButton: false,
                //     confirmButtonText: 'Go to Home Page',
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         navigate('/');
                //     }
                // });
                navigate("/company");

            } else {
                toast.error('Request Unsuccessful !');
            }
            handleClose();
        }
    }

    useEffect(() => {
        const checkCompanyAddress = async () => {

            console.log(employmentForm)
            if (employmentForm.companyName?.pincode && !employmentForm?.pincode) {
                const response = await loadPincode(employmentForm.companyName?.pincode);
                console.log(response)
                setCompanyAddressOption(response);
            }
        }
        checkCompanyAddress();
    }, [employmentForm]);



    return (
        <>
            <div className='mc-form-opener-wrapper'>
                <div className="mc-form-opener">

                    <h2 className="mc-form-opener-text">
                        {text}
                    </h2>
                    {!ifFilled && <button className={active ? "mc-btn mc-form-opener-button active" : "mc-btn mc-form-opener-button"} onClick={() => { handleOpen(id) }}>
                        <i class="fa-solid fa-plus"></i>
                        <span>Add</span>
                    </button>}
                    {ifFilled && <button className={active ? "mc-btn mc-form-opener-button active" : "mc-btn mc-form-opener-button"} onClick={() => { handleOpen(id) }}>
                        <i class="fa-solid fa-edit"></i>
                        <span>Edit</span>
                    </button>}

                </div>
                {(ifFilled) && <div
                    className='mc-filled-details-wrapper'
                >
                    <ul className='mc-filled-details-container'>
                        {ifFilled.length >= 1 && ifFilled?.map((item) => {
                            return <li className='mc-flex'>
                                <span className='mc-detail-item'>{item?.label}</span>
                                <span className='mc-detail-item'>{item?.value}</span>
                            </li>
                        })}
                    </ul>
                </div>}
            </div>
            <Popup
                state={modal || activeModal === 1}
                title={"Employment Details"}
                form={[
                    {
                        placeholder: "Employment Type*",
                        option: [
                            { label: "Full Time", value: 1, name: "employmentType" },
                            { label: "Part Time", value: 2, name: "employmentType" },
                            { label: "Remote", value: 3, name: "employmentType" }
                        ],
                        defaultValue: { label: "Full Time", value: 1 },
                        isDisabled: true,
                        onChange: (e) => handleEmploymentForm(e),
                    },

                    {
                        placeholder: "Company Name",
                        option: true,
                        onChange: (e) => handleEmploymentForm(e),
                        defaultValue: ifFilled && { label: ifFilled && ifFilled[1]?.value, value: ifFilled && ifFilled[1]?.value },
                        loadOptions: loadCompanies,
                        extends: { state: addCompany, fun: handleAddCompany }
                    },

                    {
                        placeholder: "Office Pincode",
                        option: true,
                        onChange: (e) => handleEmploymentForm(e),
                        loadOptions: loadPincode,
                        defaultValue: ifFilled && { label: ifFilled && ifFilled[2]?.value, value: ifFilled && ifFilled[2]?.value },
                    },

                    {
                        label: "Month of Joining",
                        placeholder: "Joining Month",
                        type: "month",
                        onChange: (e) => handleEmploymentForm(e),
                        name: "monthOfJoining",
                        defaultValue: ifFilled && ifFilled[3]?.value,

                    }
                ]}
                cancelButton={{
                    text: "Cancel",
                    onClick: handleClose,
                    className: "mc-btn mc-cancel-button"
                }}
                verifyButton={{
                    text: "Verify",
                    onClick: handleEmploymentFormSubmit,
                    className: isDisabledBtn[0] ? `mc-btn mc-verify-button` : `mc-btn mc-verify-button mc-disabled-btn`
                }}
            >

            </Popup>

            <Popup
                state={modal || activeModal === 2}
                title={"Kyc Details"}
                cancelButton={{
                    text: "Cancel",
                    onClick: handleClose,
                    className: "mc-btn mc-cancel-button"
                }}
                verifyButton={{
                    text: "Verify",
                    onClick: handleKYCFormSubmit,
                    className: isDisabledBtn[1] ? `mc-btn mc-verify-button` : `mc-btn mc-verify-button mc-disabled-btn`
                }}
                form={[
                    {
                        placeholder: "Pan Number",
                        type: "text",
                        onChange: (e) => { handleKycForm(e) },
                        maxLength: 10,
                        defaultValue: ifFilled && ifFilled[0]?.value
                    }
                ]}
            >

            </Popup>

            <Popup
                state={modal || activeModal === 3}
                title={"Personal Details"}
                cancelButton={{
                    text: "Cancel",
                    onClick: handleClose,
                    className: "mc-btn mc-cancel-button"
                }}
                verifyButton={{
                    text: "Verify",
                    onClick: handlePersonalDetailsFormSubmit,
                    className: isDisabledBtn[2] ? `mc-btn mc-verify-button` : `mc-btn mc-verify-button mc-disabled-btn`
                }}
                form={[
                    {
                        placeholder: "Name*",
                        type: "text",
                        onChange: (e) => { handlePersonalDetailForm(e) },
                        name: "name",
                        defaultValue: ifFilled && ifFilled[0]?.value,
                        alert: nameAlert
                    },
                    {
                        placeholder: "Date of Birth",
                        type: "date",
                        onChange: (e) => { handlePersonalDetailForm(e) },
                        name: "dob",
                        defaultValue: ifFilled && ifFilled[1]?.value
                    },
                    {
                        placeholder: "Email",
                        type: "email",
                        onChange: (e) => { handlePersonalDetailForm(e) },
                        name: "email",
                        defaultValue: ifFilled && ifFilled[2]?.value,
                        alert: emailAlert
                    },
                    {
                        placeholder: "Mobile",
                        type: "tel",
                        onChange: (e) => { handlePersonalDetailForm(e) },
                        name: "mobile",
                        defaultValue: ifFilled && ifFilled[3]?.value,
                        alert: mobileAlert
                    },
                    {

                        choices: {
                            state: activeGender || (ifFilled && (ifFilled[4]?.value === "Male" ? 1 : ifFilled[4]?.value === "Female" ? 2 : 3)),
                            type: "gender",
                            fun: handleGenderChange,
                            label: "Select Gender"
                        }

                    }
                ]}
            >

            </Popup>

            <Popup
                state={modal || activeModal === 4}
                title={"Communication Details"}
                cancelButton={{
                    text: "Cancel",
                    onClick: handleClose,
                    className: "mc-btn mc-cancel-button"
                }}
                verifyButton={{
                    text: "Verify",
                    onClick: handleCommunicationFormSubmit,
                    className: isDisabledBtn[3] ? `mc-btn mc-verify-button` : `mc-btn mc-verify-button mc-disabled-btn`
                }}
                form={[
                    {
                        placeholder: "Address",
                        type: "text",
                        onChange: (e) => { handleCommunicationForm(e) },
                        name: "address"
                    },
                    {
                        placeholder: "Pin Code",
                        onChange: (e) => { handleCommunicationForm(e) },
                        name: "pincode",
                        option: true,
                        loadOptions: loadPincode
                    },
                    {
                        placeholder: "State",
                        name: "state",
                        type: "text",
                        onChange: (e) => { handleCommunicationForm(e) },
                        value: `${((communicationDetails && communicationDetails?.pincode) && communicationDetails?.pincode?.state) || "State"}`
                    }

                ]}

            >

            </Popup >

            <Popup
                state={modal || activeModal === 5}
                title={"Add Company"}
                cancelButton={{
                    text: "Cancel",
                    onClick: handleClose,
                    className: "mc-btn mc-cancel-button"
                }}
                verifyButton={{
                    text: "Request",
                    onClick: handleAddCompanyFormSubmit,
                    className: isDisabledBtn[4] ? `mc-btn mc-verify-button` : `mc-btn mc-verify-button mc-disabled-btn`
                }}
                form={[
                    {
                        placeholder: "Company Name",
                        type: "text",
                        onChange: (e) => handleAddCompanyForm(e),
                        name: "companyName"
                    },
                    {
                        placeholder: "Company Pincode",
                        option: true,
                        name: "companyPinCode",
                        onChange: (e) => handleAddCompanyForm(e),
                        loadOptions: loadPincode
                    },
                ]}
            >
            </Popup>
        </>
    )
}