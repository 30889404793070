import React, { useContext } from "react";
import PaymentSuccess from "../components/PaymentSuccess";
import Navbar from "../components/Navbar"
import { LoaderContext } from "../context/LoaderContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const PaymentPending = () => {

    const { loading, setLoading } = useContext(LoaderContext);

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            setLoading(false);
        }
    }, [loading])

    return (
        <div>
            <Navbar />
            <div className="mc-main-component">
                <PaymentSuccess response="pending" />
                <div className="mc-btn-container">
                    <button className="mc-go-to-home-btn" onClick={() => { navigate("/") }}>Go to Home</button>
                </div>
            </div>
        </div>
    );
};

export default PaymentPending;
