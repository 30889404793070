import React from "react";

const PlanBenefits = () => {
  return (
    <div className="benefits-container">
      <div>
        <p className="plan-benefits-title">
          One Plan to protect <br /> you in case of a job loss
        </p>
      </div>
      <div className="mt-4">
        <p className="benefits">Benefits of the plan</p>
        <div className="d-flex align-items-center mb-3">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> 3 Months of salary payment</p></div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> Emergency fund for your basic needs</p></div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> Placement assistance through partners</p></div>
        </div>
        <div className="d-flex align-items-center mb-3">
          <div className="benefit-icon align-items-center"></div>
         <div> <p className="benefits-text"> Access to an exclusive placement network</p></div>
        </div>
      </div>
    </div>
  );
};

export default PlanBenefits;
