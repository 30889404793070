import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Details from "./Pages/Details";
import Home from "./Pages/Home";
import PlanPage from "./Pages/PlanPage";
import { BrowserRouter, Routes, Route, Router, useNavigate } from "react-router-dom";
import AccountProvider, { AccountContext } from "./context/AccountContext";
import Alert from "./Pages/Alert";
import { ToastContainer, toast } from "react-toastify";
import PlanProvider from "./context/PlansContext";
import { useContext, useEffect } from "react";
// import PuppeteerComponent from "./Pages/PDF";
import DownloadPDF from "./Pages/PDF";
import ProtectionPlanDownload from "./Pages/ProtectionPlanDownload";
import { choosedPlan, communicationForm, employmentForm, kycForm, personalForm } from "./redux/actions/formsAction";
import { paymentDetails, paymentLedger } from "./redux/actions/paymentAction";
import { useDispatch } from "react-redux";
import PaymentFailed from "./Pages/PaymentFailed";
import PaymentPending from "./Pages/PaymentPending";
import Swal from "sweetalert2";
import CompanyAdded from "./Pages/Company";


// eslint-disable-next-line



function App() {

  const { user, setUser } = useContext(AccountContext);
  console.log(user);

  useEffect(() => {
    if (!user?.userTransactionId) {
      if (localStorage.getItem("userTransactionId")) {
        setUser({ ...user, userTransactionId: localStorage.getItem("userTransactionId") });
      }
    }

    if (!user?.planId) {
      if (localStorage.getItem("planId")) {
        setUser({ ...user, planId: localStorage.getItem("planId"), userTransactionId: localStorage.getItem("userTransactionId") });
      }
    }
  }, [user]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(personalForm());
    dispatch(employmentForm());
    dispatch(kycForm());
    dispatch(personalForm());
    dispatch(communicationForm());
  }, [dispatch])


  // useEffect(() => {
  //   Swal.fire({
  //     title: '',
  //     text: 'Your payment has been done, We will reach out to you soon.',
  //     icon: 'info',
  //     showCancelButton: false,
  //     confirmButtonText: 'Go to Home Page',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       // navigate('/');
  //     }
  //   });
  // }, [])


  return (
    <>
      <PlanProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/form" element={<Details />} />
            <Route path="/plans" element={<PlanPage />} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/pdf" element={<DownloadPDF />} />
            <Route path="/payment-success" element={<ProtectionPlanDownload />} />
            <Route path="/payment-failed" element={<PaymentFailed />} />
            <Route path="/payment-pending" element={<PaymentPending />} />
            <Route path="/company" element={<CompanyAdded />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </PlanProvider>
    </>
  );
}

export default App;
