import axios from "axios";
import { baseurl } from "../../constants";

export const submitCommunicationForm = async (data,id) => {
    try {
        console.log(data);
        const form = {
            address: data.address,
            area: data.pincode,
            state: data.state,
            userTransactionId:id
        };
        console.log(form);
        const response = await axios.post(`${baseurl}/api/protection/communication-address`, form);
        return response;
    } catch (err) {
        return err.message;
    }
}