import { createContext, useState } from "react";
import Loader from "../components/Loader";

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);
    return <LoaderContext.Provider value={{ loading, setLoading }}>
        {loading && <Loader />}
        {children}
    </LoaderContext.Provider>
}