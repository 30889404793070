import axios from "axios";
import { baseurl } from "../../constants";

export const requestAddCompany = async (data) => {
    try {
        console.log(data);
        const form = { companyName: data.companyName, companyLocation: `${data?.companyPinCode?.locality} ${data?.companyPinCode?.city} ${data?.companyPinCode?.state}`,userTransactionId:localStorage.getItem("userTransactionId") };
        const response = await axios.post(`${baseurl}/api/protection/add-company`, form);
        return response;
    } catch (err) {
        return err.message;
    }
}