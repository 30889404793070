import axios from "axios"
import { baseurl } from "../../constants"


export const getPaymentResponse = async (data) => {
    try {
        console.log(data);
        console.log("Get payment API response called");
        const response = await axios.get(`${baseurl}/api/payment/get-payment-gateway-response?userTransactionId=${localStorage.getItem("userTransactionId")}`);
        return response;
    } catch (err) {
        return err.message;
    }
}