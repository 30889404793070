import Modal from 'react-bootstrap/Modal';
import SelectTag from './SelectTag';

export default function Popup({ state, title,text, cancelButton, verifyButton, form, ...rest }) {

    return <Modal className='mc-modal-dialog' centered show={state} aria-labelledby="contained-modal-title-vcenter">
        <div className='mc-modal-form-container'>

            {title && <h2>{title}</h2>}

            {text && <p>{text}</p>}

            {(form && form.length !== 0) && form.map((item) => {

                return <>
                    <div className='mc-input-fields-wrapper'>
                    {item?.label && <label className='mc-input-label'>{item.label}</label>}
                        {
                           
                            item.option && <div className='mc-select-tag'>
                                <SelectTag
                                    options={item.option}
                                    placeholder={item?.placeholder}
                                    loadOptions={item.loadOptions}
                                    {...item}
                                />
                                {item?.extends?.state && <div
                                    className='mc-input-field-extended mc-flex mc-flex-column'
                                >
                                    <p>Company not listed here ?</p>
                                    <button
                                        className='mc-btn mc-btn-add-company'
                                        onClick={item?.extends?.fun}

                                    >
                                        Request to add company
                                    </button>
                                </div>}
                            </div>
                        }

                        {
                            (!item?.option && item?.type) && <><input
                                type={item.type}
                                placeholder={item.placeholder}
                                {...item}
                            />
                                {(item.alert && item.alert.state) && <div className={`mc-input-alert ${item?.alert?.variant}`}>{item?.alert?.text}</div>}
                            </>
                        }

                        {(item?.choices && item?.choices.type === "gender") && <>
                            <label htmlFor="gender" className='mc-label'>{item?.choices?.label}</label>
                            <div className='mc-choices-wrapper'>
                                <button className={`mc-btn ${item?.choices?.state === 1 && "mc-gender-active"}`} onClick={() => item?.choices?.fun(1)}>Male</button>
                                <button className={`mc-btn ${item?.choices?.state === 2 && "mc-gender-active"}`} onClick={() => item?.choices?.fun(2)}>Female</button>
                                <button className={`mc-btn ${item?.choices?.state === 3 && "mc-gender-active"}`} onClick={() => item?.choices?.fun(3)}>Others</button>
                            </div>
                        </>
                        }
                    </div>
                </>
            })}

            <div className='mc-btns-container'>
                {cancelButton && <button className={cancelButton?.className || "mc-btn mc-cancel-btn"} onClick={cancelButton?.onClick}>{cancelButton?.text}</button>}
                {verifyButton && <button className={verifyButton?.className || "mc-btn mc-verify-btn"} onClick={verifyButton?.onClick}>{verifyButton?.text || "Verify"}</button>}
            </div>
        </div>
    </Modal>
}