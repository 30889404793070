import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Newsletter from "../components/Newsletter";
import UpdateSalary from "../components/UpdateSalary";
import PickAPlan from "../components/PickAPlan";
import ProtectionNeed from "../components/PlanPageRightComponent/ProtectionNeed";
import WhatIsNotCovered from "../components/PlanPageRightComponent/WhatIsNotCovered";
import Continue from "../components/PlanPageRightComponent/Continue";
import { useContext } from "react";
import { PlanContext } from "../context/PlansContext";
import { useEffect } from "react";
import { useState } from "react";
import { LoaderContext } from "../context/LoaderContext";


const PlanPage = () => {
  const { plans } = useContext(PlanContext);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [total, setTotal] = useState(
    Math.floor(plans && plans[0].premium.netAmount)
  );
  // console.log("Ab to aaja ", plans);
  const { setLoading } = useContext(LoaderContext);
  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  }, []);


    


  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-6">
            <div>
              <UpdateSalary />
            </div>
            <div className="mb-5">
              <p className="pickaplan">Pick a Plan</p>
              <PickAPlan setTotal={setTotal} setSelected={setSelectedPlan} />
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center protection-need-css">
            <div>
              <div className="mb-4">
                <ProtectionNeed />
              </div>
              <div className="mb-4">
                <WhatIsNotCovered />
              </div>
              <div className="mb-4">
                <Continue total={total} currentPlan={selectedPlan} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default PlanPage;
