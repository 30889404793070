import axios from "axios";
import { baseurl } from "../../constants";

export const getProtectionPlan = async (pData) => {
    try {
        const response = await axios.get(`${baseurl}/api/protection/protection-plan?protectionPlanId=${localStorage.getItem("protectionPlanNo")}&mobile=${pData?.mobile}`, {
            headers: {
                user_transaction_id: localStorage.getItem("userTransactionId")
            }
        });
        return response;
    } catch (err) {
        return err.message;
    }
}