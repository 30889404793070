import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        paddingVertical: 20,
    },
    section: {
        margin: 10,
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    title: {
        fontFamily: 'Poppins',
        textAlign: "center",
        color: "#333",
        fontSize: 24,
        marginBottom: 20,
    },
    table: {
        display: "table",
        width: "80%",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#333",
        margin: "0 auto 10px"
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row",
        borderBottom: "1px solid #333"
    },
    tableCol: {
        width: "25%",
        padding: "5px",
        fontSize: 16
    },
    tableData: {
        fontSize: 12
    },
    thankYou: {
        textAlign: "center",
        color: "#333",
        fontSize: 16,
    }
});

Font.register({
    family: 'Poppins',
    src: `./fonts/Poppins400.ttf`,
});

const PDFDocument = () => {
    // Sample data for the table
    const products = [
        { name: "Gucci Bag", quantity: 2, price: 55000, total: 110000 },
        { name: "Armani Shirt", quantity: 1, price: 7000, total: 7000 },
        { name: "Adidas Shoes", quantity: 3, price: 3000, total: 9000 },
    ];

    // Calculate the total amount
    const totalAmount = products.reduce((acc, product) => acc + product.total, 0);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image style={{ width: "45px" }} src="/images/bp-logo.png" alt="logo" />
                </View>
                <Text style={styles.title} fontSize={28} fontWeight={'bold'}>
                    Invoice
                </Text>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableData}>Name</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableData}>Quantity</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableData}>Price</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableData}>Total</Text>
                        </View>
                    </View>

                    {/* Table Rows */}
                    {products.map((product, index) => (
                        <View key={index} style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableData}>{product.name}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableData}>{product.quantity}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableData}>{product.price}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableData}>{product.total}</Text>
                            </View>
                        </View>
                    ))}
                </View>

                {/* Total Amount */}
                <Text style={{ textAlign: 'right', margin: '10px 40px 20px 0', color: "#333", fontSize: 14 }}>
                    Total: {totalAmount}
                </Text>

                {/* Thank You Note */}
                <Text style={styles.thankYou}>Thanks for shopping!</Text>
            </Page>
        </Document>
    );
};

export default PDFDocument;
