// DownloadPDF.js
import React, { useContext, useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocument from '../components/PDFDocument';
import { LoaderContext } from '../context/LoaderContext';

const DownloadPDF = () => {
    const { setLoading } = useContext(LoaderContext);
    useEffect(() => {
        setTimeout(() => setLoading(false), 500);
    }, []);
    return (
        <div style={{ width: "100%" }} className='mc-pdf-viewer'>
            <PDFViewer>
                <PDFDocument />
            </PDFViewer>
            <style>
                {`
                .mc-pdf-viewer iframe{
                    width:100%;
                    min-height:100vh;
                }
                `}
            </style>
        </div>

    );
};

export default DownloadPDF;
